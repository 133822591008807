import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

// create an axios instance
const service = axios.create({
  timeout: 10000, // request timeout
  baseURL: process.env.VUE_APP_BASE_API_URL + '/'
})

// request interceptor
service.interceptors.request.use((config) => {
    config.headers['Client-Locale'] = store.state.locale
    if (store.state.userToken != '') {
      config.headers['Account-Token'] = store.state.userToken
    }
    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 20000) {
      return res
    } else if (res.code === 20020) {
      Message({
        message: res.message || 'Success',
        type: 'success',
        duration: 3000,
      })
      return res
    }
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.code === 50000) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 3000
      })
    } else if (res.code === 50050) {
      // to re-login
      store.commit('userToken', '')
      store.commit('userId', '')
      MessageBox.confirm(res.message, {
        confirmButtonText: 'Re Sign In',
        cancelButtonText: 'Close',
        type: 'warning'
      }).then(() => {
        let navCount = sessionStorage.getItem('navCount') || 0
        navCount++
        sessionStorage.setItem('navCount', navCount)
        router.push('/user/login')
      })
    }
    return Promise.reject(new Error(res.message || 'Error'))
  },
  (error) => {
    Message({
      message: error.message || 'Server Error',
      type: 'error',
      duration: 3000
    })
    return Promise.reject(error)
  }
)

export default service
