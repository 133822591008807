
//第一步：需要知道小数点后面有几位小数
//需要知道小数点后面有几位小数
function digitLength(e) {
    var e1 = (e + '').split('');//把数值转为字符串，然后转为数组方便获取小数点的索引
    var e2 = e1.findIndex((item) => item = '.')
    return e1.length - 1 - e2;//小数点后面几位
}

//第二步：就是化整运算，再化为小数
//加法运算：
function add(a, b) {
    // 两种情况：一种是a小数点后面位数比较多b
    var as = Math.pow(10, this.digitLength(a))//两个数的最小公倍数
    // 另一种是b小数点后面位数比较多
    var as2 = Math.pow(10, digitLength(b))//两个数的最小公倍数
    if (digitLength(a) >= digitLength(b)) {
        return (as * a + as * b) / as
    } else {
        return (as2 * a + as2 * b) / as2
    }
    // function digitLength(e) {
    //     var e1 = (e + '').split('');
    //     var e2 = e1.findIndex((item) => item = '.')
    //     return e1.length - 1 - e2;
    // }
}

//减法运算 （加法运算跟加法一样）
function sub(a, b) {
    var as = Math.pow(10, digitLength(a))
    var as2 = Math.pow(10, digitLength(b))
    if (digitLength(a) >= digitLength(b)) {
        return (as * a - as * b) / as
    } else {
        return (as2 * a - as2 * b) / as2
    }
    // function digitLength(e) {
    //     var e1 = (e + '').split('');
    //     var e2 = e1.findIndex((item) => item = '.')
    //     return e1.length - 1 - e2;
    // }
}

//乘法运算
function mul(a, b) {
    var as = Math.pow(10, digitLength(a))
    var as2 = Math.pow(10, digitLength(b))
    if (digitLength(a) >= digitLength(b)) {
        return (as * a * as * b) / as / as//这里要除以最小公倍数的平方
    } else {
        return (as2 * a * as2 * b) / as2 / as2
    }
    // function digitLength(e) {
    //     var e1 = (e + '').split('');
    //     var e2 = e1.findIndex((item) => item = '.')
    //     return e1.length - 1 - e2;
    // }
}

//除法运算
function bed(a, b) {
    var as = Math.pow(10, digitLength(a))
    var as2 = Math.pow(10, digitLength(b))
    if (digitLength(a) >= digitLength(b)) {
        return ((as * a) / (as * b))
    } else {
        return ((as2 * a) / (as2 * b))
    }
    // function digitLength(e) {
    //     var e1 = (e + '').split('');
    //     var e2 = e1.findIndex((item) => item = '.')
    //     return e1.length - 1 - e2;
    // }
}

// 去除显示为科学计数法的问题
function toolNumber(num_str) {
    num_str = num_str.toString();
    if (num_str.indexOf("+") != -1) {
        num_str = num_str.replace("+", "");
    }
    if (num_str.indexOf("E") != -1 || num_str.indexOf("e") != -1) {
        var resValue = "",
            power = "",
            result = null,
            dotIndex = 0,
            resArr = [],
            sym = "";
        var numStr = num_str.toString();
        if (numStr[0] == "-") {
            // 如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
            numStr = numStr.substr(1);
            sym = "-";
        }
        if (numStr.indexOf("E") != -1 || numStr.indexOf("e") != -1) {
            var regExp = new RegExp(
                "^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$",
                "ig"
            );
            result = regExp.exec(numStr);
            if (result != null) {
                resValue = result[2];
                power = result[5];
                result = null;
            }
            if (!resValue && !power) {
                return false;
            }
            dotIndex = resValue.indexOf(".") == -1 ? 0 : resValue.indexOf(".");
            resValue = resValue.replace(".", "");
            resArr = resValue.split("");
            if (Number(power) >= 0) {
                var subres = resValue.substr(dotIndex);
                power = Number(power);
                //幂数大于小数点后面的数字位数时，后面加0
                for (var i = 0; i <= power - subres.length; i++) {
                    resArr.push("0");
                }
                if (power - subres.length < 0) {
                    resArr.splice(dotIndex + power, 0, ".");
                }
            } else {
                power = power.replace("-", "");
                power = Number(power);
                //幂数大于等于 小数点的index位置, 前面加0
                for (var i = 0; i < power - dotIndex; i++) {
                    resArr.unshift("0");
                }
                var n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
                resArr.splice(n, 0, ".");
            }
        }
        resValue = resArr.join("");

        return sym + resValue;
    } else {
        return num_str;
    }
}

export default {
    digitLength,
    add,
    sub,
    mul,
    bed,
    toolNumber,
}