import Vue from 'vue'
import Vuex from 'vuex'
// import sha1 from 'sha1'
Vue.use(Vuex)
const state = {
    // locale: localStorage.getItem('locale') || 'zh-TW',
    locale: localStorage.getItem('locale') || 'en-US',
    theme: localStorage.getItem('theme') || 'light',
    userToken: localStorage.getItem('userToken') || '',
    userSignKey: localStorage.getItem('userSignKey') || '',
    userId: localStorage.getItem('userId') || '',
    userEmail: localStorage.getItem('userEmail') || '',
    userPhone: localStorage.getItem('userPhone') || '',
    userVerified: localStorage.getItem('userVerified') || 0,
    marketCoin: localStorage.getItem('marketCoin') || 'USDT',
    newsID: localStorage.getItem('newsID') || 0
}
const mutations = {
    locale (state, locale) {
        if(['en-US','Latin','zh-TW','Thailand','Korea','Japan'].indexOf(locale)<0) return
        state.locale = locale
        localStorage.setItem('locale', locale)
    },
    theme (state, theme) {
        if(['light','dark'].indexOf(theme)<0) return
        state.theme = theme
        localStorage.setItem('theme', theme)
    },
    userToken (state, userToken) {
        state.userToken = userToken
        localStorage.setItem('userToken', userToken)
    },
    userEmail (state, userEmail) {
        state.userEmail = userEmail
        localStorage.setItem('userEmail', userEmail)
    },
    userPhone (state, userPhone) {
        state.userPhone = userPhone
        localStorage.setItem('userPhone', userPhone)
    },
    userId (state, userId) {
        state.userId = userId
        localStorage.setItem('userId', userId)
    },
    userVerified (state, userVerified) {
        state.userVerified = userVerified
        localStorage.setItem('userVerified', userVerified)
    },
    marketCoin (state, marketCoin) {
        state.marketCoin = marketCoin
        localStorage.setItem('marketCoin', marketCoin)
    },
    newsID (state, newsID) {
        state.newsID = newsID
        localStorage.setItem('newsID', newsID)
    },
    set (state, set) {
        if(set.key == undefined || set.val == undefined || state[set.key] == undefined) return
        state[set.key] = set.val
        localStorage.setItem(set.key, set.val)
    }
}
const store = new Vuex.Store({
    state,
    mutations
})
export default store