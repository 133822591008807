export default {
    submit: '提交',
	amount: '金额',
	price: '价格',
	available: '可用',
	total: '合计',
	no_more: '没有更多了',
	optional: '可选',
	remark: '备注',
	tips: '温馨提示',
	copied: '已复制',
	petf: '請输入此字段',
	MAX: '最大值',
    registerS:'註冊成功',
    navbar: {
        home: '首页',
        subscribe: '订阅',
        fund: '区块基金',
        help: '帮助',
        language: '语言',
        sign_in: '登录',
        my_assets: '我的资产',
        profile: '个人资料',
        settings: '设置',
        logout: '退出登录',

        new1:'新币',
        new2:'行情',
        new3:'现货',
        new4:'合约',
        new5:'法币',
    },
    footer: {
        about_us: '关于我们',
        help_center: '帮助中心',
        fees: '手续费',
        user_agreement: '服务条款',
        contact_us: '联系我们',
        copyright: '所有版权'
    },
    base: {
        back: '返回',
        buy: '买入',
        sell: '卖出',
        no_more: '空列表',
        loading: '正在加载',
        sending: '正在发送',
        pending: '处理中',
        success: '成功',
        fail: '失败',
        warning: '提示',
        danger: '警告',
        error: '错误',
        email: '邮箱',
        password: '密码',
        old_pw: '旧密码',
        new_pw: '新密码',
        re_pw: '重输密码',
        pay_password: '交易密码',
        submit: '提交',
        update: '更新',
        new: '新增',
        remark: '备注',
        voucher: '凭据',
        amount: '金额',
        enter_usdt_quantily: '請输入USDT数量',
        enter_trade_password: '請输入交易密码',
        login_first: '請先登录',
        state: '状态',
        profit: '收益',
        coin: '币',
        coin_profit: '币收益',
        refresh: '刷新',
        total: '合计',
        deal: '操作'
    },
    home: {
        // banner: [
        //     // require('@/assets/index/banner1_zh-cn.png'),
        //     // require('@/assets/index/banner2_zh-cn.png'),
        //     // require('@/assets/index/top-bg.jpg')
        //     require('@/assets/index/banner/1-1.jpg'),
        //     require('@/assets/index/banner/2-1.jpg'),
        //     require('@/assets/index/banner/3-1.jpg')
        // ],
        title1: '币牛市官网',
        title2: '快速交易 , 安全资产',
        opened: '已开放',
        more: '更多',
        trade: '交易',
        pair: '交易对',
        price: '最新价格',
        change: '波动',
        high: '最高',
        low: '最低',
        finished: '成交',
        deal: '操作',
        // service_title: '立即开始您的加密货币之旅',
        service_title: '4大卖点',
        service1: {
            title: '系统可靠',
            des1: '保证交易的安全，稳定，高效',
            des2: '世界顶尖人才的精英团队'
        },
        service2: {
            title: '资金安全',
            des1: 'Https数据加密，身份验证',
            des2: '冷钱包存储，财务级别架构，多重安全保护'
        },
        service3: {
            title: '最好体验',
            des1: '快速提款，多语言',
            des2: '高性能交易引擎，快速交易经验'
        },
        service4: {
            title: '专业服务',
            des1: '专业的国际服务团队',
            des2: '及时响应用户问题'
        },
        guide_title:'新手指南',
    },
    fund: {
        banner1: {
            title1: 'USDT本位永续合约送福利',
            title2: '百万豪礼等你抽'
        },
        banner2: {
            title1: '区块链基金',
            title2: '成功推荐朋友可以获得高额USDT'
        },
        banner3: {
            title1: 'XMR、IOTA、AR、XRT、',
            title2: 'USDT本位永续合约正式上线'
        },
        newest: '上新',
        my_participation: '我参与的',
        notify_message: '恭喜抢购成功',
        incentive_mechanism: '奖励机制',
        locked_staking: '锁仓赚币',
        ongoing: '进行中',
        sold_out: '已结束',
        countdown_starts:'5分钟后开始',
        profit: '收益',
        days: '天',
        user_limit: '参与用户数量限制',
        applicants_user: '申請用户数',
        buy_range: '参与金额范围',
        read_more: '查看详情',
        purchase: '参与',
        available: '可用',
        success_title: '提交成功',
        success_msg:'已提交成功，结束倒计时宣布抢购结果，請查看我的参与情况',
        lock_up_the_rules: '锁仓规则',
        lock_up_the_rules_1: '抢购结果在每局倒计时结束后公布',
        lock_up_the_rules_2: '锁仓时间到期后自动解锁',
        lock_up_the_rules_3: 'TAOGE收益解释：USDT总收益除以TAOGE实时价格，再转化成TAOGE的数量，这就是TAOGE的总收益',
        lock_up_the_rules_4: '锁定后获得的USDT收入会转到个人账户，TAOGE收入会被冻结到2021年8月30日，之后可以解冻自由交易',
        project_introduction: '项目介绍',
        project_introduction_content: '为什么TAOGE区块基金能有这么高的收益？我们有稳健的理财量化收益，其中有USDT跨国际市场高频对冲套利，由Cloud Cryptal Power机构及摩根大通前任CFO（Marianne Lake）参与直接管理。核心管理团队在金融领域实战经验超过15年，非常了解对冲套利的风险和应对方案，已经形成相当成熟的量化对冲策略',
        lock_period: '错仓周期',
        lock_start_time: '锁仓开始时间',
        lock_end_time: '锁仓结束时间',
        state: {
            checking: '审核中',
            locking: '锁仓中',
            finished: '完成释放',
            fail: '失败'
        }
    },
    subscribe: {
        title1: '新币订阅',
        title2: '我们致力于帮助区块链项目筹集资金，寻找支持者，并提出新颖有趣的好主意',
        new_currency: '新币',
        my_participation: '我的订阅',
        project_details: '项目详情',
        subscription_ratio: '认购比例',
        issue_quota: '发行配额',
        participating: '已参与',
        lock_period: '锁仓周期',
        months: '个月',
        thaw_interval: '解冻间隔',
        hours: '小时',
        available: '可用',
        purchase_limit: '参与限制',
        total: '合计',
        fee: '手续费',
        get_involved_now: '立即参与',
        end_date: '结束日期',
        project_des: '项目简介',
        white_paper: '白皮书'
    },
    login: {
        title: '会员登录',
        dont_have_an_account:'您还没有会员账号 ?',
        to_register: '去注册',
        email: '邮箱地址',
        password: '登录密码',
        captcha: '图形验证码',
        click_me_show: '点击显示',
        sign_in_now: '立即登录',
        forget_password: '忘记密码 ?'
    },
    register: {
        title: '会员注册',
        phone: '电话号码',
        re_password: '重复密码',
        invitation_code: '邀請码',
        email_captcha: '邮箱验证码',
        send_to_email: '发送到邮箱',
        already_have_an_account: '您已有一个会员账号 ?',
        sign_in: '登录'
    },
    forget: {
        title: '重设密码',
        retrieve_now: '立即重设'
    },
    account: {
        title: '账户',
        recharge: '充值',
        profile: '个人资料',
        my_assets: '我的资产',
        withdraw: '提现',
        transactions: '现货交易',
        micro_contract: '微合约交易',
        contract:'合约交易',
        inviting_and_awards: '邀請和返佣',
        settings: '设置',
        list_of_assets: '资产列表',
        available: '可用',
        frozen: '冻结',
        available_log: '可用记录',
        event: '事件',
        time: '时间',
        event_type: {
            recharge: '充值',
            take: '扣除',
            withdraw: '提现',
            withdraw_fail: '提现失败',
            trade_buy: '现货买入',
            trade_sell: '现货卖出',
            trade_success: '交易成功',
            trade_cancel: '交易撤单',
            subscribe: '订阅',
            contract_buy: '微合约下单',
            contract_back: '微合约返回',
            contract_win: '微合约获利',
            frozen: '冻结',
            thaw: '解冻',
            fund_buy: '区块基金买入',
            fund_buy_fail: '区块基金买入失败',
            fund_back: '区块基金本金返回',
            fund_income: '区块基金收益',
            cfl1ua: '永久直推奖励',
            cfl1u: '直推奖励',
            cfl2u: '二级奖励',
            cfl3u: '三级奖励',
            exchange: '闪兑',

            // 新增
            new25:'转出至合约账户',
            new26:'合约账户转入',

            // recharge: '充值',
            // take: '扣除',
            // withdraw: '提现',
            // withdraw_fail: '提现失败',
            // trade_buy: '交易买入',
            // trade_sell: '交易卖出',
            // trade_success: '交易成功',
            // trade_cancel: '交易撤单',
            // subscribe: '订阅',
            // contract_buy: '合约买入',
            // contract_back: '合约返回',
            // contract_win: '合约赢得',
            // frozen: '冻结',
            // thaw: '解冻',
            // fund_buy: '区块基金买入',
            // fund_buy_fail: '区块基金买入失败',
            // fund_back: '区块基金本金返回',
            // fund_income: '区块基金收益',
            // cfl1ua: '永久直推奖励',
            // cfl1u: '直推奖励',
            // cfl2u: '二级奖励',
            // cfl3u: '三级奖励',
            // exchange: '闪兑'
        },
        email: '邮箱地址',
        verify: '认证',
        verify_state: {
            unsubmitted: '未提交',
            fail: '失败',
            checking: '待审核',
            success: '完成'
        },
        addressTitle:'冷钱包地址',
    },
    wallet: {
		tb: '总资产折合',
		recharge: '充币',
		withdraw: '提币',
		exchange: '闪兑',
		in_order: '冻结',
		value: '折合',
		pea: '請输入金额',
		petp: '請输入资金密码',
		log_type: [
			'充值',
			'扣除',
			'提现',
			'提现失败',
			'交易买入',
			'交易卖出',
			'交易成功',
			'交易撤单',
			'买币',
			'合约交易买入',
			'合约交易平局',
			'合约交易胜局',
			'系统冻结',
			'系统解冻',
			'代币基金买入',
			'代币基金买入失败',
			'代币基金释放本金',
			'代币基金收益',
			'永久直推返佣',
			'直推返佣',
			'二级返佣',
			'三级返佣',
			'闪兑',
			'闪兑',
			'闪兑'
		]
	},
    recharge: {
		title: '充币',
		cc: '点击复制',
		uv: '上传凭据',
		puv: '請上传凭据',
		nor: '不开放充值',
		history: {
			title: '充币记录',
			checking: '待审核',
			success: '成功到账',
			fail: '失败'
		},
		tips: {
			USDT: [
				'請选择网络ERC20/OMNI/TRC20 ，将USDT提现到上述地址，否则资产将无法收回。 充值到上述地址后，需要确认整个网络节点，12次网络确认后确认账户，12次网络确认后确认提现。',
				'最低充值金额1USDT，低于最低充值金额将不记入且无法退款。 您的充值地址不会经常变动，如有变动，您可以充值，我们会通过网站公告或邮件通知您。',
				'請确保计算机和浏览器安全，防止信息被篡改或泄露。'			
			],
			BTC: [
				'請选择网络Bitcoin，将BTC提现至上述地址，否则资产将无法收回。 充值到上述地址后，需要确认全网节点，12次入网后确认到账，12次入网后确认提现。',
				'最低充值金额为0.0001BTC，低于最低充值金额将不记入且无法退款。 您的充值地址不会经常变动，如有变动，您可以充值，我们会通过网站公告或邮件通知您。',
				'請确保计算机和浏览器是安全的，以防止信息被篡改或泄露。 請务必上传充值截图，否则收不到。'
			],
			ETH: [
				'請选择网络Ethereum，将ETH提现到上述地址，否则资产将无法收回。 充值到上述地址后，需要确认全网节点，12次入网后确认到账，12次入网后确认提现。',
				'最低充值金额0.01ETH，低于最低充值金额将不记入且无法退款。 您的充值地址不会经常变动，如有变动，您可以充值，我们会通过网站公告或邮件通知您。',
				'請确保计算机和浏览器安全，防止信息被篡改或泄露。'
			],
			LTC: []
		}
	},
    withdraw: {
		title: '提币',
		now: '不开放提币',
        swa: '选择钱包地址',
        awa: '添加钱包地址',
        nwa: '新的钱包地址',
		history: {
			title: '提币记录',
			checking: '待审核',
			success: '成功',
			fail: '失败',
			cancel: '取消'
		}
	},
    exchange: {
		title: '闪兑',
		history: {
			title: '闪兑记录'
		}
	},
    share: {
		title: '分享好友',
		cic: '复制邀請码',
		t: '区块链基金推广分享奖励机制',
		t1: '直接推广代理',
		t1_1: '1. 直接推广2个人以下获得交易流水2%USDT奖励',
		t1_2: '2. 直接推广3个人以上（包含3个人）获得交易流水4%USDT奖励',
		t1_3: '3. 直接推广5个人以上（包含5个人）获得交易流水6%USDT奖励',
		t1_4: '4. 直接推广10个人以上（包含10个人）获得交易流水8%USDT奖励',
		t2: '二级代理',
		t2_1: '1. 二级代理人达到4人以下，获得交易流水1%USDT奖励',
		t2_2: '2. 二级代理达到4人（包含4个人），获得交易流水2%USDT奖励',
		t2_3: '3. 二级代理达到6人（包含6个人），获得交易流水4%USDT奖励',
		t3: '三级代理',
		t3_1: '1. 三级代理达到1人，获得交易流水3%USDT奖励',
		t3_2: '2. 三级代理达到4人（包含4个人），获得交易流水6%USDT奖励',
		t3_3: '3. 三级代理达到6人（包含6个人），获得交易流水8%USDT奖励',
		team: {
			title: '邀請喝返佣',
			ct1: '合计邀請人数',
			ct2: '今日邀請人数',
			ct3: '今日收益',
			ct4: '合计收益',
			ct5: '合计余额',
			level_1: '一级', 
			level_2: '二级', 
			level_3: '三级',
		}
	},
    user: {
		email: '邮箱地址',
		email_captcha: '邮箱验证码',
		captcha: '图片验证码',
		pwd: '密码',
		confirm_pwd: '确认密码',
		trade_pwd: '资金密码',
		confirm_trade_pwd: '确认资金密码',
		wa: '钱包地址',
		peea: '請输入邮箱地址',
		pewa: '請输入钱包地址',
		pec: '請输入图片验证码',
		peec: '請输入邮箱验证码',
		cfii: '图形验证码格式为字母和数字组成',
		ecfii: '邮箱验证码为数字组成',
		eafii: '邮箱地址格式错误',
		pep: '請输入密码',
		pecp: '請确认密码',
		p_diff: '两次输入的密码不一致',
		pfii: '密码为字母和数字和符号组成',
		uff: '上传文件失败'
	},
    contact_about:{
        label1:'合约下单',
        top_text_h1:'净值',
        top_text_h2:'保证金',
        top_text_h3:'持仓收益',
        available:'可用余额',
        fundswap:'资金互换',
        form:'从',
        to:'到',
        pair:'对币',
        num:'数量',
        num_plc:'請输入数量',
        all:'全部',
        cancel:'取消',
        confirm:'确定',
        margin:'保证金',
        lever:'杠杆',
        please_choose:'請选择',
        contract_order:'合约订单',
        position_order:'持仓订单',
        current_commission:'当前委托',
        switchBtn:'只看当前品种',
        liquidation:'平仓',
        table1:{
            pair:'对币',
            type:'类型',
            income:'收益',
            rate_return:'收益率',
            deal:'交易',

            span_h1:'委托价',
            span_h2:'开仓价',
            span_h3:'保证金',
            span_h4:'最新价',
            span_h5:'委托时间',
            span_h6:'开仓时间',
            span_h7:'杠杆',
        },
        table2:{
            type:'类型',
            price:'价格',
            amount:'数量',
            time:'时间',
            deal:'交易',
        },
        recharge_text1:"USDT现货钱包",
        recharge_text2:"USDT合约钱包",
        error1:'請输入数量且不能大于余额',
    },
    recharge_new:{
        verified_tip:'請先通过认证再使用充值功能',
        table_item1:'订单号',
        table_item2:'状态',
        table_item3:'类型',
        table_item4:'金额',
        table_item5:'凭证',
        table_item6:'时间',

        table_item7:'地址',

        table_state1:'提交中',
        table_state2:'成功',
        table_state3:'失败',

        amount_mes:'纯数字且最多保留2位小数',
        message_img:'請上传凭证图片',
        message_password:'交易密码不能为空',
        message_bal:'提现金额不能大于余额',
    },
    address:{
        title:'冷钱包地址',
        wallet_address:'請输入冷钱包地址',
        wallet_list_title:'冷钱包列表',
        wallet_type:'类型',
        wallet_address_title:'冷钱包地址',
        wallet_opea:'操作',
        wallet_cancel:'取消',
        wallet_err:'钱包地址不能为空',
        wallet_tip:'限制10个冷钱包',
    },
    //现货订单列表
    transactions:{
        span1:'类型',
        span2:'对币/本币',
        span3:'委托价',
        span4:'数量',
        span5:'成交价',
        span6:'总价',
        span7:'时间',
        span8:'状态',

        cancelBtn:'取消订单',

        state_new1:'委托下单',
        state_new2:'用户撤销',
        state_new3:'成交订单',
        state_new4:'系统撤销',
    },
    micro_contract:{
        span1:'类型',
        span2:'对币/本币',
        span3:'数量',
        span4:'周期/收益率',
        span5:'收益',
        span6:'结算价格',
        span7:'结算时间',
        span8:'状态',

        state1:'待处理',
        state2:'成功',
        state4:'失败',
    },

    // 现货详情
    trading_index:{
        el_title:'现货下单',
        el_title1:'委托订单',
        el_switch1:'只看当前品种',
        message1:'买入总价不能超过余额',
        message2:'买入数量不能超过余额',
    },

    user_contract:{
        placeholder1:'請选择',
        span1:'撤销',
        span2:'用户撤销',
        span4:'已平仓',
        span5:'系统撤销',
        rentext1:'全部币种',
        rentext2:'全部类型',
        rentext3:'买入订单',
        rentext4:'卖出订单',
        rentext5:'全部状态',
        rentext6:'委托下单',
        rentext7:'用户撤销',
        rentext8:'成交',
        rentext9:'平仓',
        rentext10:'系统撤销',
    },

    //个人设置导航
    settings:{
        nav:'KYC 认证',
        nav:'登录密码',
        nav:'交易密码',
        nav:'登录记录',
    },

    fiat_currency:{
        message:{
            tips:'提示',
            p_userToken:'請先登录',
            p_userVerified:'請先通过实名认证',
            confirm:"确认",
            cancel:"取消",
        }
    },

    r:{
        r1:"註冊需瀏覽並同意",
        r2:"用戶協議",
    }
}