export default {
    b_buy:'매수',
    b_sell:'매도',

    c_buy:'매수/롱',
    c_sell:'매도/숏',

    n_detail:'상세정보',
    n_more:'공고',

    currency_exchange_rate:'화폐 환율',
    submit: '제출',
	amount: '금액',
	price: '가격',
	available: '사용 가능',
	total: '합계',
	no_more: '더 이상 없어요.',
	optional: '옵션',
	remark: '설명',
	tips: '따뜻한 힌트',
	copied: '복사됨',
	petf: '이 필드를 입력하십시오.',
	MAX: '최대값',
    buyUp:"매입",
    buyDown:"매수하다",
    look:"보기",
    id_image_a:"주민등록증 앞 사진 올려주세요.",
    id_image_b:"신분증 올리고 찍어주세요.",
    rfields:"필수 항목",
    
    imageLimit:"그림 파일 크기는 5m를 초과할 수 없습니다.",

    search_text:"가상자산 또는 심볼명 검색",
    registerS:'등록 성공',
    shouyi_t:'총수익률',

    // 新加
    required_address0:'등록 주소',
    required_address:'등록 주소를 입력하십시오.',
    required_swift:'swift code 를 입력하십시오.',

    phoneVerified:'연락처',
    phoneVerifiederr:'연락처 입력',

    //公賬驗證
    corporate_account_power:{
        h_1:"달러 대공 계좌 검증 신청",
        p_1:"달러 입금은 대공 계좌 검증을 통과해야만 조작할 수 있다",
        btn:"제출 검증",
        h_2:"힌트",
        p_2:"당신은 이미 달러 대공 계좌 검증을 제출했으니, 심사가 통과될 때까지 기다려 주십시오",
    },

    //交易方向
    onJump1:"현물 거래",
    onJump2:"계약 거래",

    news_center:"뉴스 센터",

    middle_window:{
        Position:"위치",
        Price:"가격",
        Amount:"수량",
    },
    Confirm_order:{
        title:"주문 확인",
        h:"현재 가격",
        Due_time:"마감 시간 (초)",
        Profit:"이익",
        label1:"투자 금액 (단일 투자 10개 이상)",
        plc1:"투자 금액을 입력하다",
        Expected_profits:"예상 이익",
        Guaranteed_amount:"보증금액",
    },
    navbar: {
        home: '첫 페이지',
        subscribe: '가입',
        fund: '메모리 블록 펀드',
        help: '고객지원',
        language: '언어',
        sign_in: '로그인',
        my_assets: '내 자산',
        profile: '프로필',
        settings: '설치',
        logout: '로그인 종료',

        new1:'새코인발행과 상장',
        new2:'마켓',
        new3:'현물',
        new4:'계약',
        new5:'법화',
        // new_new:"新幣行情",
        new_new:"활성 화폐",
    },
    footer: {
        about_us: '우리에 대해',
        help_center: '도움말 센터',
        fees: '수수료',
        user_agreement: '서비스 약관',
        contact_us: '고객센터',
        copyright: '모든 저작권',
        qualification: '자격 정보',
    },
    base: {
        back: '반환',
        buy: '매입',
        sell: '매도',
        no_more: '빈 목록',
        loading: '로드 중',
        sending: '보내는 중',
        pending: '처리 중',
        success: '성공',
        fail: '실패',
        warning: '힌트',
        danger: '경고',
        error: '오류',
        email: '메일박스',
        password: '암호',
        old_pw: '이전 암호',
        new_pw: '새 암호',
        re_pw: '비밀번호 재전송',
        pay_password: '거래 암호',
        submit: '제출',
        update: '업데이트',
        new: '신규',
        remark: '설명',
        voucher: '자격 증명',
        amount: '금액',
        enter_usdt_quantily: 'USDT 수를 입력하십시오.',
        enter_trade_password: '거래 암호를 입력하십시오.',
        login_first: '먼저 로그인하십시오.',
        state: '상태',
        profit: '이익',
        coin: '화폐',
        coin_profit: '코인 수익',
        refresh: '새로 고침',
        total: '합계',
        deal: '조작'
    },
    home: {
        // banner: [
        //     require('@/assets/index/banner/1-1.jpg'),
        //     require('@/assets/index/banner/2-1.jpg'),
        //     require('@/assets/index/banner/3-1.jpg')
        // ],
        title1: 'Happycoinage',
        title2: '신속한 거래, 보안 자산',
        opened: '오픈',
        more: '추가',
        trade: '마켓',
        pair: '가상자산명',
        price: '현재가',
        change: '변동률',
        high: '최고',
        low: '최소',
        finished: '거래',
        deal: '조작',
        // service_title: '立即開始您的加密貨幣之旅',
        service_title: '하이라이트 “빅4”',
        service1: {
            title: '시스템 신뢰성',
            des1: '안전하고 안정적이며 효율적인 거래 보장',
            des2: '세계 최고 인재의 엘리트 팀'
        },
        service2: {
            title: '자금 안전',
            des1: 'Https 데이터 암호화, 인증',
            des2: '콜드 월렛 스토리지, 재무 수준 아키텍처, 다중 보안'
        },
        service3: {
            title: '최고의 경험',
            des1: '빠른 출금, 다국어',
            des2: '고성능 트랜잭션 엔진, 빠른 트랜잭션 경험'
        },
        service4: {
            title: '프로 서비스',
            des1: '전문 국제 서비스 팀',
            des2: '사용자 질문에 즉시 응답'
        },
        guide_title:'신입 가이드',
    },
    fund: {
        banner1: {
            title1: 'USDT 본위 영구 계약 혜택',
            title2: '백만호례. 뽑을 때까지 기다릴게요.'
        },
        banner2: {
            title1: '메모리 블록 체인 펀드',
            title2: '친구 추천에 성공하면 높은 USDT를 받을 수 있습니다'
        },
        banner3: {
            title1: 'XMR、IOTA、AR、XRT、',
            title2: 'USDT 본위 영구 계약 정식 출시'
        },
        newest: '상신',
        my_participation: '내가 참여했어',
        notify_message: '사재기 성공을 축하합니다.',
        incentive_mechanism: '장려제도',
        locked_staking: '창고를 잠그고 돈을 벌다.',
        ongoing: '진행 중',
        sold_out: '종료',
        countdown_starts:'5분 뒤에 시작합니다.',
        profit: '이익',
        days: '일',
        user_limit: '참여 사용자 수 제한',
        applicants_user: '신청 사용자 수',
        buy_range: '참여 금액 범위',
        read_more: '자세히 보기',
        purchase: '참여',
        available: '사용 가능',
        success_title: '커밋 성공',
        success_msg:'제출 성공, 카운트다운 종료 사재기 결과 발표, 나의 참여 상황을 확인하세요',
        lock_up_the_rules: '창고 잠금 규칙',
        lock_up_the_rules_1: '사재기 결과는 매회 카운트다운이 끝난 후에 발표된다',
        lock_up_the_rules_2: '창고 잠금 시간 만료 후 자동 잠금 해제',
        lock_up_the_rules_3: 'TAOGE 수익 해석: USDT 총수익을 TAOGE 실시간 가격으로 나눈 다음 TAOGE의 수량으로 전환한다. 이것이 바로 TAOGE의 총수익이다.',
        lock_up_the_rules_4: '잠금 후 얻은 USDT 수입은 개인 계좌로 이체되고, TAOGE 수입은 2021년 8월 30일까지 동결되며, 이후 자유 거래를 동결할 수 있다',
        project_introduction: '프로젝트 소개',
        project_introduction_content: '왜 TAOGE 메모리 블록 펀드가 이렇게 높은 수익을 낼 수 있습니까?우리는 안정적인 재테크 양적 수익을 가지고 있으며, 그 중 USDT가 국제 시장을 넘나드는 고주파 헤지 차익을 가지고 있으며, Cloud Cryptal Power 기관 및 JP모건 체이스의 전임 CFO (Marianne Lake) 가 직접 관리에 참여한다.핵심 경영진은 금융 분야에서 실전 경험이 15년이 넘고, 헤지 차익의 위험과 대응 방안을 잘 알고 있으며, 이미 상당히 성숙한 양적 헤지 전략을 형성하였다',
        lock_period: '오창 주기',
        lock_start_time: '창고 잠금 시작 시간',
        lock_end_time: '창고 잠금 종료 시간',
        state: {
            checking: '심사 중',
            locking: '창고를 잠그는 중',
            finished: '석방 완료',
            fail: '실패'
        }
    },
    subscribe: {
        title1: '새 코인 구독',
        title2: '우리는 메모리 블록 체인 프로젝트가 자금을 조달하고 후원자를 찾고 새롭고 흥미로운 아이디어를 제시하는 데 전념하고 있습니다.',
        new_currency: '새 화폐',
        my_participation: '내 구독',
        project_details: '프로젝트 상세 정보',
        subscription_ratio: '청약 비율',
        issue_quota: '발행 할당량',
        participating: '참여',
        lock_period: '창고 잠금 주기',
        months: '개월',
        thaw_interval: '동결해제 간격',
        hours: '시간',
        available: '사용 가능',
        purchase_limit: '참여 제한',
        total: '합계',
        fee: '수수료',
        get_involved_now: '지금 참여',
        end_date: '종료 날짜',
        project_des: '프로젝트 소개',
        white_paper: '백서'
    },
    login: {
        title: '회원 로그인',
        dont_have_an_account:'당신은 아직 회원 계정이 없습니다 ?',
        to_register: '등록하러 가다',
        email: '사서함 주소',
        password: '로그인 암호',
        captcha: '그래픽 인증 코드',
        click_me_show: '표시 클릭',
        sign_in_now: '지금 로그인',
        forget_password: '암호를 잊었습니다?',

        emailPhone:'메일박스 또는 휴대폰 번호',
        min6:'(최소 6비트)',
        rules1:'이메일이나 핸드폰 번호를 입력하세요',
        rules2:'최소 6자리 암호를 입력하십시오.',
        rules3:'최소 6자리 반복 암호를 입력하십시오.',
        rules3_2:'두 번 입력한 암호가 일치하지 않습니다.',
        rules4:'초대 코드를 입력하십시오.',
        rules5:'등록하려면 사용자 계약을 탐색하고 동의해야 합니다.',
        captcha_plc:'최소 4자리 인증번호를 입력하십시오.',
    },
    register: {
        title: '회원 등록',
        phone: '전화번호',
        re_password: '암호 반복',
        invitation_code: '초대 코드',
        email_captcha: '메일박스 인증 코드',
        send_to_email: '메일로 보내기',
        already_have_an_account: '당신은 이미 회원 계정이 있습니다 ?',
        sign_in: '로그인'
    },
    forget: {
        title: '암호 재설정',
        retrieve_now: '지금 재설정'
    },
    account: {
        title: '계정',
        recharge: '충전',
        profile: '프로필',
        my_assets: '내 자산',
        withdraw: '현금 인출',
        transactions: '현물 거래',
        micro_contract: '마이크로 계약 거래',
        contract:'계약 거래',
        inviting_and_awards: '초대 및 커미션 반환',
        settings: '설치',
        list_of_assets: '자산 목록',
        available: '사용 가능',
        frozen: '동결',
        available_log: '사용 가능한 레코드',
        event: '이벤트',
        time: '시간',
        event_type: {
            recharge: '충전',
            take: '공제',
            withdraw: '현금 인출',
            withdraw_fail: '현금 인출 실패',
            trade_buy: '현물 매입',
            trade_sell: '현물 판매',
            trade_success: '거래 성공',
            trade_cancel: '거래 취소',
            subscribe: '가입',
            contract_buy: '마이크로 계약 주문',
            contract_back: '마이크로 계약 반환',
            contract_win: '마이크로 계약 이익',
            frozen: '동결',
            thaw: '해동',
            fund_buy: '메모리 블록 펀드 매입',
            fund_buy_fail: '메모리 블록 펀드 매입 실패',
            fund_back: '메모리 블록 펀드 원금 반환',
            fund_income: '메모리 블록 펀드 이익',
            cfl1ua: '영구 직추 보상',
            cfl1u: '직추 보상',
            cfl2u: '2 레벨 보상',
            cfl3u: '3 레벨 보상',
            exchange: '환전',

            // 新增
            new25:'계약 계정으로 이동',
            new26:'계약 계좌 이체',
            new27:'법화 충전',
            new28:'법화 현금 인출',
            new29:'신권 인수',
            new30:'장려금',
            new31:'서비스 요금',
        },
        email: '사서함 주소',
        verify: '인증',
        verify_state: {
            unsubmitted: '커밋되지 않음',
            fail: '실패',
            checking: '보류 중',
            success: '완료'
        },
        verify_page:{
            rules1:'신분증 이름을 입력하십시오.',
            rules2:'주민등록번호를 입력하십시오.',
        },
        addressTitle:'콜드 지갑 주소',
    },
    wallet: {
		tb: '총자산 환산',
		recharge: '충전',
		withdraw: '동전 인출',
		exchange: '환전',
		in_order: '동결',
		value: '환산',
		pea: '금액을 입력하세요',
		petp: '자금 비밀번호를 입력하십시오.',
		log_type: [
			'충전',
			'공제',
			'현금 인출',
			'현금 인출 실패',
			'거래 매수',
			'거래 매도',
			'거래 성공',
			'거래 취소',
			'화폐를 사다',
			'계약 거래 매수',
			'계약 거래 무승부',
			'계약 거래의 승국',
			'시스템 고정',
			'시스템 동결해제',
			'토큰 펀드 매입',
			'토큰 펀드 매입 실패',
			'토큰 펀드가 원금을 방출하다',
			'토큰 펀드 수익',
			'영구 직불 커미션',
			'직접 커미션을 반환하다',
			'2 레벨 커미션 반환',
			'3 레벨 커미션 반환',
			'환전',
			'환전',
			'환전'
		]
	},
    recharge: {
		title: '충전',
		cc: '복사 클릭',
		uv: '자격 증명 업로드',
		puv: '자격 증명을 업로드하십시오.',
		nor: '오픈 충전하지 않음',
		history: {
			title: '충전 기록',
			checking: '보류 중',
			success: '입금 성공',
			fail: '실패'
		},
		tips: {
			USDT: [
				'네트워크 ERC20/OMNI/TRC20을 선택하여 위의 주소로 USDT를 현금으로 인출하십시오. 그렇지 않으면 자산을 회수할 수 없습니다.위 주소로 충전하면 전체 네트워크 노드를 확인하고 12회 네트워크 확인 후 계좌 확인, 12회 네트워크 확인 후 현금 인출을 확인해야 한다.',
				'최소 충전 금액 200USDT, 최소 충전 금액 미만은 기록되지 않으며 환불이 불가능합니다.귀하의 충전 주소는 자주 변동되지 않습니다. 변동이 있으면 충전할 수 있습니다. 저희는 사이트 공고나 우편을 통해 귀하께 통지할 것입니다.',
				'정보가 변조 또는 유출되지 않도록 컴퓨터와 브라우저를 안전하게 보호하십시오.'			
			],
			BTC: [
				'네트워크 ERC20/OMNI/TRC20을 선택하여 위의 주소로 BTC를 현금으로 인출하십시오. 그렇지 않으면 자산을 회수할 수 없습니다.위 주소로 충전하면 전체 네트워크 노드를 확인하고 12회 네트워크 확인 후 계좌 확인, 12회 네트워크 확인 후 현금 인출을 확인해야 한다.',
				'최소 충전 금액 200USDT, 최소 충전 금액 미만은 기록되지 않으며 환불이 불가능합니다.귀하의 충전 주소는 자주 변동되지 않습니다. 변동이 있으면 충전할 수 있습니다. 저희는 사이트 공고나 우편을 통해 귀하께 통지할 것입니다.',
				'정보가 변조 또는 유출되지 않도록 컴퓨터와 브라우저를 안전하게 보호하십시오.'			
			],
			ETH: [
                '네트워크 ERC20/OMNI/TRC20을 선택하여 위의 주소로 ETH를 현금으로 인출하십시오. 그렇지 않으면 자산을 회수할 수 없습니다.위 주소로 충전하면 전체 네트워크 노드를 확인하고 12회 네트워크 확인 후 계좌 확인, 12회 네트워크 확인 후 현금 인출을 확인해야 한다.',
				'최소 충전 금액 200USDT, 최소 충전 금액 미만은 기록되지 않으며 환불이 불가능합니다.귀하의 충전 주소는 자주 변동되지 않습니다. 변동이 있으면 충전할 수 있습니다. 저희는 사이트 공고나 우편을 통해 귀하께 통지할 것입니다.',
				'정보가 변조 또는 유출되지 않도록 컴퓨터와 브라우저를 안전하게 보호하십시오.'			
			],
			LTC: []
		}
	},
    withdraw: {
		title: '동전 인출',
		now: '코인 인출 불가',
        swa: '지갑 주소 선택',
        awa: '지갑 주소 추가',
        nwa: '새 지갑 주소',
		history: {
			title: '동전 인출 기록',
			checking: '보류 중',
			success: '성공',
			fail: '실패',
			cancel: '취소'
		}
	},
    exchange: {
		title: '환전',
		history: {
			title: '환전 기록'
		}
	},
    share: {
		title: '친구 공유',
		cic: '초대 코드 복사',
		t: '메모리 블록 체인 기금 보급 공유 장려 메커니즘',
		t1: '직접 홍보 에이전트',
		t1_1: '1. 직접 2명 이하 프로모션하여 거래 흐름 2% USDT 보상 획득',
		t1_2: '2. 직접 3명 이상 홍보(3명 포함) 거래 흐름 4% USDT 보상 획득',
		t1_3: '3. 직접 5명 이상 홍보(5명 포함) 거래 흐름 6% USDT 보상 획득',
		t1_4: '4. 직접 10명 이상 홍보(10명 포함) 거래 흐름 8% USDT 보상 획득',
		t2: '2단계 에이전트',
		t2_1: '1. 2급 대리인이 4인 이하 달성, 거래 흐름 1% USDT 보상 획득',
		t2_2: '2. 2급 대리 4명 달성(4명 포함), 거래 흐름 2% USDT 보상 획득',
		t2_3: '3. 2급 대리 6명 달성(6명 포함), 거래 흐름 4% USDT 보상 획득',
		t3: '3 단계 에이전트',
		t3_1: '1. 3 레벨 대리 1 명 달성, 거래 흐름 3% USDT 보상 획득',
		t3_2: '2. 3급 대리 4명 달성(4명 포함), 거래 흐름 6% USDT 보상 획득',
		t3_3: '3. 3 레벨 에이전트 6 명 (6 명 포함) 달성, 거래 흐름 8% USDT 보상 획득',
		team: {
			title: '리베이트 초청',
			ct1: '총 초대 인원',
			ct2: '오늘 초청 인원',
			ct3: '오늘의 이익',
			ct4: '합계 이익',
			ct5: '합계 잔액',
			level_1: '일급', 
			level_2: '2급', 
			level_3: '3급',
		}
	},
    user: {
		email: '사서함 주소',
		email_captcha: '메일박스 인증 코드',
		captcha: '그림 인증 코드',
		pwd: '암호',
		confirm_pwd: '암호 확인',
		trade_pwd: '자금 암호',
		confirm_trade_pwd: '자금 비밀번호 확인',
		wa: '지갑 주소',
		peea: '이메일 주소를 입력하십시오.',
		pewa: '지갑 주소를 입력하십시오.',
		pec: '그림 인증 코드를 입력하십시오.',
		peec: '메일박스 인증번호를 입력하십시오.',
		cfii: '그래픽 인증 코드 형식은 문자 및 숫자로 구성됩니다.',
		ecfii: '메일박스 인증번호는 숫자로 구성되어 있습니다.',
		eafii: '메일박스 주소 형식 오류',
		pep: '암호를 입력하십시오.',
		pecp: '비밀번호 확인',
		p_diff: '두 번 입력한 암호가 일치하지 않습니다.',
		pfii: '암호는 문자와 숫자 및 기호로 구성됩니다.',
		uff: '파일 업로드 실패'
	},
    contact_about:{
        label1:'계약 주문',
        top_text_h1:'순가치',
        top_text_h2:'보증금',
        top_text_h3:'매수 포지션 수익',
        available:'사용 가능한 잔액',
        fundswap:'자금 교환',
        form:'부터',
        to:'도착',
        pair:'코인',
        num:'수량',
        num_plc:'수량을 입력하십시오.',
        all:'모두',
        cancel:'취소',
        confirm:'확인',
        margin:'보증금',
        margin1:'남은 보증금',
        lever:'레버',
        please_choose:'선택하십시오',
        contract_order:'계약 주문',
        position_order:'보유 주문',
        current_commission:'현재 위임',
        switchBtn:'현재 품종만 본다',
        liquidation:'포지션 닫기',
        table1:{
            pair:'코인',
            type:'유형',
            income:'이익',
            rate_return:'수익률',
            deal:'거래',

            span_h1:'위탁가',
            span_h2:'창고 개설 가격',
            span_h3:'보증금',
            span_h4:'최신 가격',
            span_h5:'위탁 시간',
            span_h6:'포지션 오픈 시간',
            span_h7:'레버',

            span_h8:'클로우스드 가격',
            span_h9:'포지션 클로우스드 시간',

            service_charge:'수수료',
        },
        table2:{
            type:'유형',
            price:'가격',
            amount:'수량',
            time:'시간',
            deal:'거래',

            service_charge:'서비스 요금',
        },
        recharge_text1:"USDT 현물 지갑",
        recharge_text2:"USDT 계약 지갑",
        error1:'수량을 입력하십시오. 잔액보다 클 수 없습니다.',
    },
    recharge_new:{
        verified_tip:'먼저 인증 후 충전 기능 사용',
        table_item1:'주문 번호',
        table_item2:'상태',
        table_item3:'유형',
        table_item4:'금액',
        table_item5:'자격 증명',
        table_item6:'시간',

        table_item7:'주소',

        table_state1:'제출 중',
        table_state2:'성공',
        table_state3:'실패',

        amount_mes:'순수 숫자 및 최대 2자리 소수점',
        amount_mes2:'순수 숫자 및 최대 8자리 소수점',
        message_img:'자격 증명 이미지를 업로드하십시오.',
        message_password:'거래 암호는 비워둘 수 없습니다.',
        message_bal:'현금 인출 금액은 잔액보다 클 수 없다.',

        table_item_new1:'화폐 종류로 넘어가다',
        table_item_new2:'전입 수량',
        table_item_new3:'입금 화폐 종류',
        table_item_new4:'입금 수량',
    },
    address:{
        title:'콜드 지갑 주소',
        wallet_address:'콜드 지갑 주소를 입력하십시오.',
        wallet_list_title:'콜드 지갑 목록',
        wallet_type:'유형',
        wallet_address_title:'콜드 지갑 주소',
        wallet_opea:'작업',
        wallet_cancel:'취소',
        wallet_err:'지갑 주소는 비워둘 수 없습니다.',
        wallet_tip:'콜드 지갑 10개 제한',
    },
    //現貨訂單列表
    transactions:{
        span1:'유형',
        span2:'코인 / 코인',
        span3:'위탁가',
        span4:'수량',
        span5:'거래 가격',
        span6:'총 가격',
        span7:'시간',
        span8:'상태',

        cancelBtn:'주문 취소',

        state_new1:'위탁 주문',
        state_new2:'사용자 실행 취소',
        state_new3:'거래 주문',
        state_new4:'시스템 실행 취소',
    },
    micro_contract:{
        span1:'유형',
        span2:'코인 / 코인',
        span3:'수량',
        span4:'주기 / 수익률',
        span5:'이익',
        span6:'결제 가격',
        span7:'결제 시간',
        span8:'상태',

        state1:'보류 중',
        state2:'성공',
        state4:'실패',
    },

    // 現貨詳情
    trading_index:{
        el_title:'현물 주문',
        el_title1:'주문 위임',
        el_switch1:'현재 품종만 본다',
        message1:'매입 총가격은 잔액을 초과해서는 안 된다.',
        message2:'매입 수량은 잔액을 초과해서는 안 된다.',

        // 新加市價限價
        tab_1:'시가 위탁',
        tab_2:'가격 제한 위탁',
    },

    user_contract:{
        placeholder1:'선택하십시오.',
        span1:'실행 취소',
        span2:'사용자 실행 취소',
        span4:'포지션 클로우스드',
        span5:'시스템 실행 취소',
        rentext1:'전체 화폐',
        rentext2:'모든 유형',
        rentext3:'매입 주문',
        rentext4:'매도 주문',
        rentext5:'전체 상태',
        rentext6:'위탁 주문',
        rentext7:'사용자 실행 취소',
        rentext8:'거래',
        rentext9:'창고 정리',
        rentext10:'시스템 실행 취소',
    },

    //個人設置導航
    settings:{
        nav1:'KYC 인증',
        nav2:'로그인 암호',
        nav3:'거래 암호',
        nav4:'로그인 레코드',
        ipAddress:"IP 주소",
        Date_time:"시간",
    },

    fiat_currency:{
        message:{
            tips:'힌트',
            p_userToken:'먼저 로그인하십시오.',
            p_userVerified:'실명인증 먼저 해주세요.',
            confirm:"확인",
            cancel:"취소",
        },
        onTab1:"법화 환입",
        onTab2:"USDT 빠른 판매",
        onTab3:"달러 입금",
        onTab4:"법정 화폐 지불",
        span1:"거래상",
        span2:"수량",
        span3:"한도액",
        span4:"단가",
        span5:"지불 방식",
        span6:"거래",
        span7:"수수료",
        bank:"은행카드",
        open:"USDT 구매",

        fait_pay:{
            inp1:'충전 금액（USDT）',
            plc1:'충전 금액을 입력하세요',
            inp2:'결제 금액',
            plc2:'결제 금액 자동 계산',
        },

        recycle:{
            inp1:"총 판매 수량",
            plc1:"총 판매 수량",
            inp2:"화폐 종류",
            plc2:"코인을 선택하세요",
            inp3:"총 판매 금액",
            plc3:"총 판매 금액",
            inp4:"은행카드",
            plc4:"은행카드를 선택하세요",
            submitForm:"제출",
            recycling_tips_title:"특별 주의사항",
            recycling_tip1:"플랫폼 인증 실명 은행카드를 사용하십시오. 수취인 성명과 플랫폼 등록 성명이 일치하지 않아 회수할 수 없습니다.",
            recycling_tip2:"주문 러시아워에는 주문 처리량이 많기 때문에 회수 시간이 길어질 수 있으니 기다려 주십시오.",
            recycling_tip3:"USDT 익스프레스 매도 집중 회수 시간은 12.00AM 및 05:00PM(법정 공휴일 또는 비근무일 제외)이다.",
        },
        public:{
            dt:"공개 계정 정보",
            dd1:"수취인",
            dd2:"수금 계좌",
            dd3:"은행명",
            dd4:"은행 주소",
            bankFn:"달러 입금",
        },
        dialog1:{
            title:"구매",
            h5:"결제 전 반드시 읽기",
            p1:"중요: 계좌이체 주의사항은 계좌이체 시 비고하지 마십시오.",
            p2:"계좌이체 주의사항: 계좌이체 시'가상화폐','USDT'등 가상화폐와 관련된 단어를 비고하지 마십시오. 은행카드가 동결되고 계좌이체가 차단되지 않도록 하십시오.",
            p3:"계좌 실명인증의 성명 아래 은행카드로 이체하다.",
            p4:"러시아워에는 화폐 방출이 좀 느리니 참을성 있게 기다려 주십시오.",
            span1:"수량",
            span2:"한도액",
            span3:"단가",
            buynum:"구매 수량을 입력하십시오.",
            allbuyFn:"모두 매입",
            pay_for:"실비",
            comeFn:"확인",
        },
        dialog2:{
            title:"달러 입금",
            label1:"금액",
            placeholder1:"금액을 입력하세요",
            label2:"자격 증명",
            submitBank:"제출",
        },
        rules:{
            message1:"총 판매 수량을 입력하십시오.",
            message2:"은행카드를 선택하세요",
            message3:"금액을 입력하세요",
            message4:"자격 증명을 업로드하십시오.",
            err_message1:"구매 수량을 입력하십시오.",
            err_message2:"실불 금액은 반드시 한도액 구간 내에 있어야 한다.",
            err_message3:"구매 수량이 보유 수량을 초과하다",
        },

        buy:{
            h2:"USDT 구매",
            span1:"수량",
            span2:"단가",
            span3:"실비",
            item2_h:"판매자 수금 방식",
            item2_p1:"본인을 꼭 사용하세요.",
            item2_p2:"의 지불 방식은 아래 계좌로 자동 이체합니다",
            msg_h1:"수금 은행",
            msg_h2:"지점 이름",
            msg_h3:"은행 계좌",
            msg_h4:"은행 코드",
            msg_h5:"송금 노트",
            msg_h6:"자격 증명 업로드",
            upload_text:"파일을 여기로 드래그하거나 업로드 클릭",
            upload_tip:"그림 파일만 업로드할 수 있으며 8m를 초과하지 않습니다.",
            submitFn:"자격 증명 제출",
            dealers_img:"거래상",
            flag_no:"이미 모든 데이터입니다.",
            placeholder:"내용을 입력하고'Enter'키를 눌러 제출하십시오.",
            message1:"문자를 입력하거나 그림을 선택하여 보내주세요",
            message2:"전송 실패",
        },
    },

    index:{
		guide_box:{
			item1:"등록",
			item2:"입금",
			item3:"거래",
			item4:"거래",
			item1_text:"등록 지금 10USDT 받기",
			item2_text:"입금 즉시 20 USDT 획득",
			item3_text:"거래 즉시 50USDT 획득",
			item4_text:"10000USDT 이상 거래 즉시 100USDT 획득",
		},
    },

    Help_center:"도움말 센터",
    account_fiat1:"은행카드",
    account_fiat2:"프랑스 화폐 주문",
    account_fiat3:"USDT 빠른 판매 주문",

    account_fiat4:"새 코인 구매 주문",

    account_bank:{
        placeholder0:"코인을 선택하세요",

        placeholder1:"수취인을 입력하십시오.",
        placeholder2:"수납은행에 입력하십시오.",
        placeholder3:"지점 정보를 입력하십시오.",
        placeholder4:"수금 계좌를 입력하십시오.",
        placeholder5:"은행 코드를 입력하십시오.",
        submitForm:"제출",
        list_title:"은행 카드 목록",
        list_span1:"수취인",
        list_span2:"은행명",
        list_span3:"지점 정보",
        list_span4:"수금 계좌",
        list_span5:"이체 코드",
        list_span6:"작업",
        onDel:"삭제",
        message1:"수취인을 입력하십시오.",
        message2:"수납은행에 입력하십시오.",
        message3:"수금 계좌를 입력하십시오.",
        err_message1:"은행카드 10장 제한",
        onDel_h:"이 작업은 은행 카드를 영구적으로 삭제합니다. 계속하시겠습니까?",
        onDel_tips:"힌트",
        confirmButtonText:"확인",
        cancelButtonText:"취소",
    },
    account_fiat:{
        title:"프랑스 화폐 주문",
        select_placeholder:"상태 선택",
        select_val1:"진행 중",
        select_val2:"완료됨",
        select_val3:"주문 실패",
        col1:"주문 번호",
        col2:"금액",
        col3:"화폐 종류",
        col4:"가격",
        col5:"프랑스 화폐 금액",
        col6:"상태",
        status1:"진행 중",
        status2:"완료됨",
        status3:"주문 실패",
        span_h1:"수취인 이름",
        span_h2:"수금 은행",
        span_h3:"지점 이름",
        span_h4:"수금 계좌",
        span_h5:"은행 코드",
        span_h6:"은행 주소",
        span_h7:"송금 노트",
        span_h8:"생성 시간",
        span_h9:"자격 증명",
    },
    account_recycle:{
        title:"USDT 빠른 판매 주문",
        select:"상태 선택",
        select_val1:"진행 중",
        select_val2:"완료됨",
        select_val3:"주문 실패",
        span1:"주문 번호",
        span2:"금액",
        span3:"화폐 종류",
        span4:"가격",
        span5:"프랑스 화폐 금액",
        span6:"상태",
        status1:"진행 중",
        status2:"완료됨",
        status3:"주문 실패",
        span_h1:"수취인 이름",
        span_h2:"수금 은행",
        span_h3:"지점 이름",
        span_h4:"수금 계좌",
        span_h5:"은행 코드",
        span_h6:"은행 주소",
        span_h7:"송금 노트",
        span_h8:"생성 시간",
        span_h9:"자격 증명",
    },
    settings_password:{
        placeholder1:"이전 암호를 입력하십시오.",
        placeholder2:"새 암호를 입력하십시오.",
        placeholder3:"새 암호를 다시 입력하십시오.",
        submit:"제출",
        message:"두 번 입력한 암호는 동일해야 합니다.",
    },
    settings_paypassword:{
        placeholder1:"메일박스 입력",
        placeholder2:"새 거래 암호를 입력하십시오.",
        placeholder3:"새 거래 암호를 다시 입력하십시오.",
        placeholder4:"메일박스 인증번호를 입력하십시오.",
        submit:"제출",
        send:"메일 보내기",
        message:"두 번 입력한 암호는 동일해야 합니다.",
    },
    account_details:{
        router:"계약서 지갑 내역",
        span1:"유형",
        span2:"상태",
        span3:"금액",
        span4:"시간",
        type0:"매입",
        type1:"매수하다",
        type2:"전입",
        type3:"전출",
        type4:"결제",
        type5:"수수료",
        type6:"시스템 패닝",
        type7:"시스템 운영",
        state0:"보류 중",
        state1:"완료",
        state2:"실패",
    },

    r:{
        r1:"등록하려면 찾아보고 동의해야 합니다.",
        r2:"사용자 프로토콜",
    },

    new_list:{
        h:"신권 마켓",
        span2:"발행 가격",
        span3:"발행 날짜",
    },
    issue:{
        onOrderShow:"요청서",
        issue_amount:"발행 수량",
        use_issue_amount:"이미 청약한 물량",
        close_day:"닫힌 일 수",
        label:"요청 수량",
        placeholder:"요청서 수량을 입력하십시오.",
        Cancel:"취소",
        Submit:"제출",
        detail:"상세 정보",
    },
    issue_order:{
        col1:"거래 쌍",
        col2:"청약가",
        col3:"수량",
        col4:"합계 금액",
        col5:"닫힌 일 수",
        col6:"생성 시간",
    },

    contract_shenqin:{
        text_notice1:'당신은 실명인증을 제출하지 않았습니다. 먼저 실명인증을 제출하십시오',
        text_notice2:'실명인증 실패, 실명인증 다시 제출하세요',
        text_notice3:'귀하의 실명 인증은 심사 중입니다.',
        tips:'힌트',
        confirmButtonText:'확인',
        cancelButtonText:'취소',
        // confirm_text:'合約交易需要資產達到10000USDT以上，請提交申請',
        confirm_text:'계약 신청서를 제출하시고, 확인 을 클릭하여 신청을 완료하시고, 신청 성공 후 바로 거래하실 수 있습니다',
        dataVerify_text:'귀하의 계약 거래 권한 신청이 처리 중입니다. 처리를 기다려 주십시오.',
    },


    is_cancel_order:"주문을 취소하시겠습니까?",
    cancel_order:"주문 취소",
    send_img:"자격 증명 업로드",

    // 現貨新增市價限價
    youjia:"현재 최적 가격으로 거래",
    shijia_chao:"사용 가능한 잔액 초과",
    allBuyValueFnShijia_p:"금액",
    allSellValueFnShijia_p:"수량",

    xinbiyure:{
        issue_price:'발행 가격',
        issue_date:'발행 날짜',
        issue_amount:'발행 수량',
        guimo:'모금 규모',
        use_issue_amount:'누적 신청 한도',
        close_day:'동결 일 수',
        published_date:'당첨 발표 날짜',
        showToast1:"요청 가능 한도 초과",
        showToast1_rengou:"남은 청약 한도를 초과하다.",
        issue_plc:"요청 금액을 입력하십시오.",

        can_num_h:"신청 가능 한도",
        orderFormData_amount1:"요청 금액을 입력하십시오.",
        can_num_h1:"잔여 청약 한도",
        orderFormData_amount2:"청약 금액을 입력하세요",

        // 新增可用餘額usdt（現貨）
        can_num_h_u:"사용 가능한 잔액",  
        showToast_u:"사용 가능한 잔액 초과",

        span1:"거래 쌍",
        span2:"요청 금액",
        span3:"상태",
        span4:"당첨 한도",
        span5:"잔여 청약 한도",
        span6:"요청 시간",
        span7:"작업",
        buyFn:"인수",
        state_arr1:"요청중",
        state_arr2:"당첨",
        state_arr3:"완료",
        state_arr4:"완료",
        amount_message:"청약 금액을 입력하세요",
    },

    recharge_manage:{
        title:'관리비 지갑'
    },

    money_error:{
        text1:'비워둘 수 없음',
        text2:'소수점 두 자리만 유지',
    },
}