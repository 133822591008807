// import IndexVue from './views/index.vue'
import Vue from 'vue'
import store from './store'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


const routes = [
    { 
        path: '/',
        component: () => import('@/views/index'),
        children: [
            {
                path: '',
                component: () => import('@/views/index/index'),
                meta: {
                    title: '首頁',
                    title1:'Home',
                    title2:'Ante paginam',

                    title3:'หน้าหลัก',
                    title4:'첫 페이지',
                    title5:'トップページ',
                }
            },
            {
                path: 'user/login',
                component: () => import('@/views/user/login'),
                meta: {
                    title: '登錄',
                    title1:'Log in',
                    title2:'Log in',

                    title3:'เข้าสู่ระบบ',
                    title4:'로그인',
                    title5:'ログイン＃ログイン＃',
                }
            },
            {
                path: 'user/forget',
                component: () => import('@/views/user/forget'),
                meta: {
                    title: '忘記密碼',
                    title1:'Forgot password',
                    title2:'oblitus es tesserae',

                    title3:'ลืมรหัสผ่าน',
                    title4:'암호를 잊었습니다.',
                    title5:'パスワードを忘れる',
                }
            },
            {
                path: 'user/register',
                component: () => import('@/views/user/register'),
                meta: {
                    title: '註冊',
                    title1:'Register',
                    title2:'mandare',

                    title3:'ลงทะเบียน',
                    title4:'등록',
                    title5:'登録',
                }
            },
            {
                path: 'user/account',
                component: () => import('@/views/user/account/index'),
                meta: { auth: true },
                children: [
                    {
                        path: '',
                        redirect: 'profile'
                    },
                    {
                        path: 'profile',
                        component: () => import('@/views/user/account/profile'),
                        meta: {
                            title: '個人資料',
                            title1:'Personal information',
                            title2:'Personalis notitia',

                            title3:'ข้อมูลส่วนตัว',
                            title4:'프로필',
                            title5:'個人情報',
                        }
                    },
                    {
                        path: 'assets',
                        component: () => import('@/views/user/account/assets'),
                        meta: {
                            title: '我的資產',
                            title1:'My assets',
                            title2:'bonorum meorum',

                            title3:'ทรัพย์สินของฉัน',
                            title4:'내 자산',
                            title5:'マイ資産',
                        }
                    },
                    {
                        // path: 'recharge/:coin',
                        path: 'recharge',
                        name: 'Recharge',
                        component: () => import('@/views/user/account/recharge'),
                        meta: {
                            title: '充幣',
                            title1:'Deposit',
                            title2:'deposit',

                            title3:'การชาร์จเหรียญ',
                            title4:'충전',
                            title5:'貨幣を充填する',
                        }
                    },
                    {
                        path: 'recharge_manage/:coin',
                        name: 'Recharge_manage',
                        component: () => import('@/views/user/account/recharge_manage'),
                        meta: {
                            title: '管理費錢包',
                            title1:'Management fee wallet',
                            title2:'Management fee wallet',

                            title3:'ค่าธรรมเนียมการจัดการ Wallet',
                            title4:'관리비 지갑',
                            title5:'管理費財布',
                        }
                    },
                    {
                        path: 'withdraw/:coin',
                        name: 'Withdraw',
                        component: () => import('@/views/user/account/withdraw'),
                        meta: {
                            title: '提幣',
                            title1:'Withdraw',
                            title2:'recedere',

                            title3:'หยิบเหรียญ',
                            title4:'동전 인출',
                            title5:'貨幣をつりあげる',
                        }
                    },
                    {
                        path: 'exchange',
                        name: 'exchange',
                        component: () => import('@/views/user/account/exchange'),
                    },
                    {
                        path: 'subscribe',
                        component: () => import('@/views/user/account/subscribe'),
                    },
                    {
                        path: 'transactions',
                        component: () => import('@/views/user/account/transactions'),
                        meta: {
                            title: '現貨交易',
                            title1:'Spot Trading',
                            title2:'Macula Trading',

                            title3:'การซื้อขายสปอต',
                            title4:'현물 거래',
                            title5:'現物取引',
                        }
                    },
                    {
                        path: 'micro_contract',
                        component: () => import('@/views/user/account/micro_contract'),
                        meta: {
                            title: '微合約交易',
                            title1:'Micro contract transactions',
                            title2:'Contractus Micro transactions',

                            title3:'การซื้อขายสัญญาไมโคร',
                            title4:'마이크로 계약 거래',
                            title5:'マイクロ契約取引',
                        }
                    },
                    {
                        path: 'contract',
                        component: () => import('@/views/user/account/contract'),
                        meta: {
                            title: '合約交易',
                            title1:'Contract transaction',
                            title2:'contractus transaction',

                            title3:'การซื้อขายสัญญา',
                            title4:'계약 거래',
                            title5:'契約取引',
                        }
                    },
                    {
                        path: 'inviting',
                        component: () => import('@/views/user/account/inviting'),
                    },
                    //新增冷錢包相關
                    {
                        path: 'address',
                        component: () => import('@/views/user/account/address'),
                        meta: {
                            title: '冷錢包地址',
                            title1:'Cold wallet address',
                            title2:'frigus pera oratio',

                            title3:'ที่อยู่กระเป๋าสตางค์เย็น',
                            title4:'콜드 지갑 주소',
                            title5:'コールドウォレットアドレス',
                        }
                    }, 
                    //銀行卡
                    {
                        path: 'bank',
                        component: () => import('@/views/user/account/bank'),
                        meta: {
                            title: '銀行卡',
                            title1:'Bank card',
                            title2:'Bank card',

                            title3:'บัตรธนาคาร',
                            title4:'은행카드',
                            title5:'銀行カード',
                        }
                    }, 

                    //把法幣的USDT快捷賣出 換去個人中心裡面
                    {
                        path: 'quick_sale',
                        component: () => import('@/views/user/account/quick_sale'),
                        meta: {
                            title: 'USDT快捷賣出',
                            title1:'USDT quick sell',
                            title2:'USDT quick sell',

                            title3:'USDT ขายอย่างรวดเร็ว',
                            title4:'USDT 빠른 판매',
                            title5:'USDTエクスプレス・セールス',
                        }
                    }, 

                    //法幣訂單
                    {
                        path: 'fiat',
                        component: () => import('@/views/user/account/fiat'),
                        meta: {
                            title: '法幣訂單',
                            title1:'Fiat Order',
                            title2:'Fiat ordo',

                            title3:'สั่งซื้อ French',
                            title4:'프랑스 화폐 주문',
                            title5:'外貨建て注文書',
                        }
                    }, 
                    //USDT快捷賣出訂單
                    {
                        path: 'recycle',
                        component: () => import('@/views/user/account/recycle'),
                        meta: {
                            title: 'USDT快捷賣出訂單',
                            title1:'Official recycling order',
                            title2:'Officialis redivivus ordinem',

                            title3:'USDT คำสั่งขายอย่างรวดเร็ว',
                            title4:'USDT 빠른 판매 주문',
                            title5:'USDTエクスプレス受注',
                        }
                    }, 
                    //合約錢包明細
                    {
                        path: 'details',
                        component: () => import('@/views/user/account/details'),
                        meta: {
                            title: '合約錢包明細',
                            title1:'Contract wallet details',
                            title2:'Contractus pera singula',

                            title3:'รายละเอียดกระเป๋าสตางค์สัญญา',
                            title4:'계약서 지갑 내역',
                            title5:'契約財布の詳細',
                        }
                    }, 
                    //新增新幣申購訂單
                    {
                        path: 'issue_order',
                        component: () => import('@/views/user/account/issue_order'),
                        meta: {
                            title: '新幣申購訂單',
                            title1:'New currency purchase order',
                            title2:'Novae monetæ emptio ordinem',

                            title3:'สั่งซื้อเหรียญใหม่',
                            title4:'새 코인 구매 주문',
                            title5:'新規通貨発注',
                        }
                    }, 
                ]
            },
            {
                path: 'user/settings',
                component: () => import('@/views/user/settings/index'),
                meta: { auth: true },
                children: [
                    {
                        path: '',
                        redirect: 'verify'
                    },
                    {
                        path: 'verify',
                        component: () => import('@/views/user/settings/verify'),
                        meta: {
                            title: 'KYC 認證',
                            title1:'KYC certification',
                            title2:'KYC certificationem',

                            title3:'การรับรอง KYC',
                            title4:'KYC 인증',
                            title5:'KYC認証',
                        }
                    },
                    {
                        path: 'password',
                        component: () => import('@/views/user/settings/password'),
                        meta: {
                            title: '設置密碼',
                            title1:'Set password',
                            title2:'set password',

                            title3:'ตั้งรหัสผ่าน',
                            title4:'암호 설정',
                            title5:'パスワードの設定',
                        }
                    },
                    {
                        path: 'pay-password',
                        component: () => import('@/views/user/settings/pay-password'),
                        meta: {
                            title: '設置交易密碼',
                            title1:'Set transaction password',
                            title2:'Set transaction password',

                            title3:'ตั้งรหัสผ่านการซื้อขาย',
                            title4:'거래 암호 설정',
                            title5:'取引パスワードの設定',
                        }
                    },
                    {
                        path: 'login-logs',
                        component: () => import('@/views/user/settings/login-logs'),
                        meta: {
                            title: '登錄記錄',
                            title1:'Registration record',
                            title2:'adnotatione record',

                            title3:'บันทึกการเข้าสู่ระบบ',
                            title4:'로그인 레코드',
                            title5:'ログオンレコード',
                        }
                    }
                ]
            },
            {
                path: 'market/:pair/:coin',
                component: () => import('@/views/market/index'),
                meta: {
                    title: '行情詳情',
                    title1:'Quote Details',
                    title2:'Quote Details',

                    title3:'รายละเอียดตลาด',
                    title4:'시세 상세 정보',
                    title5:'相場の詳細',
                }
            },

            //新增新幣預告詳情
            {
                path: 'issue/:pair/:coin/:id',
                component: () => import('@/views/issue/index'),
                meta: {
                    title: '新幣預告詳情',
                    title1:'New currency warm-up details',
                    title2:'Novae monetæ fermentum details',

                    title3:'รายละเอียดตัวอย่างเหรียญใหม่',
                    title4:'새 코인 예고 상세 정보',
                    title5:'新貨予告詳細',
                }
            },

            //新增新幣詳情
            {
                path: 'new_market/:pair/:coin',
                component: () => import('@/views/new_market/index'),
                meta: {
                    title: '新幣詳情',
                    title1:'New Currency Details',
                    title2:'Nova Currency Details',

                    title3:'รายละเอียดเหรียญใหม่',
                    title4:'새 코인 상세 정보',
                    title5:'新貨詳細',
                }
            },

            // 新增各詳情路由
            {
                path: 'trading/:pair/:coin',
                component: () => import('@/views/trading/index'),
                meta: {
                    title: '現貨詳情',
                    title1:'Spot Details',
                    title2:'Macula Details',

                    title3:'รายละเอียดสปอต',
                    title4:'현물 상세 정보',
                    title5:'現物詳細',
                }
            },
            {
                path: 'contact/:pair/:coin',
                component: () => import('@/views/contact/index'),
                meta: {
                    title: '合約詳情',
                    title1:'Contract Details',
                    title2:'Contractus Details',

                    title3:'รายละเอียดสัญญา',
                    title4:'계약 상세 정보',
                    title5:'契約の詳細',
                }
            },

            // 新增各列表路由
            {
                path: 'list/market_list',
                component: () => import('@/views/list/market_list'),
                meta: {
                    title: '行情列表',
                    title1:'Quote list',
                    title2:'Quote list',

                    title3:'รายการตลาด',
                    title4:'시세 목록',
                    title5:'相場リスト',
                }
            },
            {
                path: 'list/trading_list',
                component: () => import('@/views/list/trading_list'),
                meta: {
                    title: '現貨列表',
                    title1:'Spot list',
                    title2:'Macula album',

                    title3:'รายการสปอต',
                    title4:'현물 목록',
                    title5:'現品リスト',
                }
            },
            {
                path: 'list/contact_list',
                component: () => import('@/views/list/contact_list'),
                meta: {
                    title: '合約列表',
                    title1:'Contract list',
                    title2:'Index contractus',

                    title3:'รายชื่อสัญญา',
                    title4:'계약 목록',
                    title5:'契約リスト',
                }
            },    
            //新幣預告
            {
                path: 'list/new_list',
                component: () => import('@/views/list/new_list'),
                meta: {
                    title: '新幣預告',
                    title1:'New Coin Notice',
                    title2:'New Coin Notice',

                    title3:'ตัวอย่างเหรียญใหม่',
                    title4:'새 코인 예고',
                    title5:'新貨予告',
                }
            },


            //新增新幣行情
            {
                path: 'list/newmarket_list',
                component: () => import('@/views/list/newmarket_list'),
                meta: {
                    title: '新幣行情',
                    title1:'New Currency Quotes',
                    title2:'Novus Currency Quotes',

                    title3:'ตลาดเหรียญใหม่',
                    title4:'신권 시세',
                    title5:'新貨相場',
                }
            },
            
            //新增法幣交易
            {
                path: 'fiat_currency/index',
                component: () => import('@/views/fiat_currency/index'),
                meta: {
                    title: '法幣交易',
                    title1:'Fiat currency transaction',
                    title2:'Fiat monetæ transaction',

                    title3:'การซื้อขายสกุลเงินฝรั่งเศส',
                    title4:'법화 거래',
                    title5:'法貨取引',
                }
            },
            {
                path: 'fiat_currency/buy',
                component: () => import('@/views/fiat_currency/buy'),
                meta: {
                    title: '購買法幣',
                    title1:'Buy fiat currency',
                    title2:'buy fiat monetæ',

                    title3:'ซื้อ French Coin',
                    title4:'법화를 구매하다',
                    title5:'法貨を買う',
                }
            },


            {
                path: 'subscribe',
                redirect: 'subscribe/index'
            },
            {
                path: 'subscribe/index',
                component: () => import('@/views/subscribe/index'),
            },
            {
                path: 'subscribe/log',
                meta: { auth: true },
                component: () => import('@/views/subscribe/log')
            },
            {
                path: 'subscribe/details/:coin',
                component: () => import('@/views/subscribe/details')
            },
            {
                path: 'fund',
                redirect: 'fund/index'
            },
            {
                path: 'fund/index',
                component: () => import('@/views/fund/index')
            },
            {
                path: 'fund/log',
                meta: { auth: true },
                component: () => import('@/views/fund/log')
            },

            {
                path: 'help',
                redirect: 'help/6',
                meta: {
                    title: '聯繫我們',
                    title1:'Contact us',
                    title2:'nobis loquere',

                    title3:'ติดต่อเรา',
                    title4:'연락처',
                    title5:'連絡先',
                }
            },
            {
                path: 'help/:id',
                component: () => import('@/views/help/index'),
                meta: {
                    title: '幫助中心',
                    title1:'Help Center',
                    title2:'Auxilium Center',

                    title3:'ศูนย์ช่วยเหลือ',
                    title4:'도움말 센터',
                    title5:'ヘルプセンター',
                }
            },
            {
                path: 'notice',
                component: () => import('@/views/notice/index'),
                meta: {
                    title: '通知',
                    title1:'Notice',
                    title2:'Notice',

                    title3:'ประกาศ',
                    title4:'알림',
                    title5:'に知らせる',
                }
            },
            {
                path: 'notice/details/:id',
                component: () => import('@/views/notice/details'),
                meta: {
                    title: '通知詳情',
                    title1:'Notification Details',
                    title2:'Notification Details',

                    title3:'รายละเอียดการแจ้งเตือน',
                    title4:'알림 상세 정보',
                    title5:'詳細を通知',
                }
            },
            {
                path: 'fees',
                component: () => import('@/views/fees')
            },
            {
                path: '*',
                component: () => import('@/views/404')
            }
        ]
    },
    
]
const router = new VueRouter({
    routes
})
router.onError(() => {
    console.log('路由錯誤')
})
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        if(localStorage.getItem('locale')=='zh-TW'||!localStorage.getItem('locale')){
            document.title = to.meta.title;
        }else if(localStorage.getItem('locale')=='en-US'){
            document.title = to.meta.title1;
        }else if(localStorage.getItem('locale')=='Latin'){
            document.title = to.meta.title2;
        }else if(localStorage.getItem('locale')=='Thailand'){
            document.title = to.meta.title3;
        }else if(localStorage.getItem('locale')=='Korea'){
            document.title = to.meta.title4;
        }else if(localStorage.getItem('locale')=='Japan'){
            document.title = to.meta.title5;
        }
        
    }

    if(to.meta.auth && store.state.userToken === ''){
        let navCount = sessionStorage.getItem('navCount') || 0
        navCount++
        sessionStorage.setItem('navCount', navCount)
        router.push('/user/login')
    } else {
        next()
    }
})
Vue.prototype.$nav = {
    back: function() {
        let navCount = sessionStorage.getItem('navCount') || 0
        if(navCount > 0){
            // console.log('正常返回')
            router.back()
        }else{
            // console.log('返回重寫')
            router.replace('/')
        }
    },
    replace: function(route) {
        router.replace(route)
    },
    push: function(route) {
        let navCount = sessionStorage.getItem('navCount') || 0
        navCount++
        sessionStorage.setItem('navCount', navCount)
        router.push(route)
    }
}
window.onpopstate = function() {
    let navCount = sessionStorage.getItem('navCount') || 0
    if(navCount > 0){
        navCount--
        sessionStorage.setItem('navCount', navCount)
    }
}
export default router