import Vue from 'vue'
import App from './App.vue'

import store from './store'

import { i18n } from './langs'

import ElementUI from 'element-ui';
import './styles/theme.scss'
// import 'element-ui/lib/theme-chalk/index.css';
// import './styles/style.scss';
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, { locale });

import router from './router'
import axios from './services/axios'
import requestNotState from './services/requestNotState'

// import io from 'vue-socket.io'
import io from './hyoga-uni-socket_io/uni-socket.io.js'

//浮点处理
import floatingpoint from './floatingpoint'
//压缩图片
import imgCompress from './img_compress'

// //滚动插件
// import VueScroller from 'vue-scroller'
// Vue.use(VueScroller)

Vue.prototype.$wsn = ''
if(window.location.protocol=='http:'){
  Vue.prototype.$wsn = 'ws://'+window.location.host
}else{
  Vue.prototype.$wsn = 'wss://'+window.location.host
}

Vue.prototype.$request = axios
Vue.prototype.$requestNotState = requestNotState

Vue.prototype.$io = io
Vue.prototype.floatingpoint = floatingpoint

Vue.prototype.$imgCompress = imgCompress

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
