// export const locale = localStorage.getItem('locale') || 'zh-TW'
export const locale = localStorage.getItem('locale') || 'en-US'
import zh_CN from './zh-CN'
import zh_TW from './zh-TW'
import en_US from './en-US'
import Latin from './Latin'
import Thailand from './Thailand'
import Korea from './Korea'
import Japan from './Japan'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale,
  messages: {
      'zh-CN': zh_CN,
      'zh-TW': zh_TW,
      'en-US': en_US,
      'Latin': Latin,
      'Thailand': Thailand,
      'Korea': Korea,
      'Japan': Japan,
  }
})