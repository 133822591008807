export default {
    b_buy:'Buy',
    b_sell:'Sell',

    c_buy:'Buy',
    c_sell:'Sell',

    n_detail:'詳細',
    n_more:'発表',

    currency_exchange_rate:'通貨レート',
    submit: '送信',
	amount: '金額',
	price: '価格',
	available: '使用可能',
	total: '合計＃ゴウケイ＃',
	no_more: 'これ以上ない',
	optional: 'オプション',
	remark: 'コメント',
	tips: '暖かいヒント',
	copied: 'コピー済み',
	petf: 'このフィールドを入力してください',
	MAX: '最大値',
    buyUp:"買い増し",
    buyDown:"買いが落ちる",
    look:"表示＃ヒョウジ＃",
    id_image_a:"身分証明書の前写真をアップロードしてください",
    id_image_b:"身分証明書の写真をアップロードしてください",
    rfields:"必須項目",
    
    imageLimit:"画像ファイルのサイズは5 mを超えてはいけません",

    search_text:"通貨を入力してください（例：BTC）",
    registerS:'登録に成功しました',
    shouyi_t:'総収益率',

    // 新加
    required_address0:'登録アドレス',
    required_address:'登録先を入力してください',
    required_swift:'swift codeと入力してください',

    phoneVerified:'連絡先',
    phoneVerifiederr:'連絡先を入力してください',

    //公賬驗證
    corporate_account_power:{
        h_1:"公的口座に対するドルの検証の申請",
        p_1:"ドル入出金は公的口座の検証を経なければ操作できない",
        btn:"検証の送信",
        h_2:"ヒント",
        p_2:"米ドルによる公的口座の検証を提出しました。確認が完了するまでお待ちください",
    },

    //交易方向
    onJump1:"現物取引",
    onJump2:"契約取引",

    news_center:"プレスセンター",

    middle_window:{
        Position:"位置",
        Price:"価格",
        Amount:"数量",
    },
    Confirm_order:{
        title:"注文の確認",
        h:"現在の価格",
        Due_time:"カットオフ時間（秒）",
        Profit:"に利益を与える",
        label1:"投資額（1投資額は10以上）",
        plc1:"投資金額の入力",
        Expected_profits:"予想利益",
        Guaranteed_amount:"保証金額",
    },
    navbar: {
        home: 'トップページ',
        subscribe: '購読',
        fund: 'メモリブロック基金',
        help: 'ヘルプ',
        language: '言語',
        sign_in: 'ログイン＃ログイン＃',
        my_assets: 'マイ資産',
        profile: '個人情報',
        settings: '設定＃セッテイ＃',
        logout: 'ログオンの終了',

        new1:'新貨予告',
        new2:'相場',
        new3:'現物',
        new4:'契約',
        new5:'フランス貨幣',
        // new_new:"新幣行情",
        new_new:"アクティブ通貨",
    },
    footer: {
        about_us: '私たちについて',
        help_center: 'ヘルプセンター',
        fees: '手数料',
        user_agreement: 'サービス条件',
        contact_us: '連絡先',
        copyright: 'すべての著作権',
        qualification: '資格情報',
    },
    base: {
        back: 'リターンマッチ',
        buy: '購入する',
        sell: '売り出す',
        no_more: '空のリスト',
        loading: 'ロード中',
        sending: '送信中',
        pending: '処理中',
        success: '成功',
        fail: 'に失敗',
        warning: 'ヒント',
        danger: 'に警告',
        error: 'エラー',
        email: 'メールボックス',
        password: 'パスワード',
        old_pw: '古いパスワード',
        new_pw: '新しいパスワード',
        re_pw: '再送信パスワード',
        pay_password: 'トランザクションパスワード',
        submit: '送信',
        update: '更新',
        new: '新規作成',
        remark: 'コメント',
        voucher: '認証情報',
        amount: '金額',
        enter_usdt_quantily: 'USDT数量を入力してください',
        enter_trade_password: '取引パスワードを入力してください',
        login_first: '先にログインしてください',
        state: 'ステータス',
        profit: 'に利益を与える',
        coin: '貨幣',
        coin_profit: '貨幣収益',
        refresh: 'リフレッシュ',
        total: '合計＃ゴウケイ＃',
        deal: '操作'
    },
    home: {
        // banner: [
        //     require('@/assets/index/banner/1-1.jpg'),
        //     require('@/assets/index/banner/2-1.jpg'),
        //     require('@/assets/index/banner/3-1.jpg')
        // ],
        title1: 'Happycoinage',
        title2: '迅速な取引、安全な資産',
        opened: 'オープン',
        more: '詳細',
        trade: '取り引き',
        pair: '取引ペア',
        price: '最新価格',
        change: 'ゆらぎ',
        high: '最高',
        low: '最小',
        finished: '成約する',
        deal: '操作',
        // service_title: '立即開始您的加密貨幣之旅',
        service_title: '4つの主な特長',
        service1: {
            title: 'システム信頼性',
            des1: '取引の安全、安定、効率を保証する',
            des2: '世界トップ人材のエリートチーム'
        },
        service2: {
            title: '資金セキュリティ',
            des1: 'Httpsデータ暗号化、認証',
            des2: 'コールドウォレットストレージ、財務レベルアーキテクチャ、多重セキュリティ'
        },
        service3: {
            title: '最高の体験',
            des1: 'クイックキャッシュ、多言語',
            des2: '高性能トランザクションエンジン、迅速なトランザクション経験'
        },
        service4: {
            title: 'プロフェッショナルサービス',
            des1: '専門の国際サービスチーム',
            des2: 'ユーザーの問題にタイムリーに対応'
        },
        guide_title:'初心者向けガイド',
    },
    fund: {
        banner1: {
            title1: 'USDT本位永続契約による福利厚生',
            title2: '百万豪礼はあなたが引くのを待っています'
        },
        banner2: {
            title1: 'メモリブロックチェーン基金',
            title2: '友人に高額なUSDTを獲得することを提案することに成功した'
        },
        banner3: {
            title1: 'XMR、IOTA、AR、XRT、',
            title2: 'USDT本位永続契約が正式にオンラインに'
        },
        newest: '上新',
        my_participation: '私が参加した',
        notify_message: '買い占め成功おめでとうございます',
        incentive_mechanism: 'インセンティブ制度',
        locked_staking: '倉庫に鍵をかけて貨幣を稼ぐ',
        ongoing: '進行中',
        sold_out: '終了',
        countdown_starts:'5分後に開始',
        profit: 'に利益を与える',
        days: '日',
        user_limit: '参加ユーザー数の制限',
        applicants_user: '応募ユーザー数',
        buy_range: '参加金額範囲',
        read_more: '詳細の表示',
        purchase: '参加する',
        available: '使用可能',
        success_title: 'コミット成功',
        success_msg:'提出に成功し、カウントダウンを終えて買い占め結果を発表しました。私の参加状況を見てください',
        lock_up_the_rules: 'ロッキング規則',
        lock_up_the_rules_1: '買占め結果は各局カウントダウン終了後に発表',
        lock_up_the_rules_2: 'ロック時間が切れたら自動的にロック解除',
        lock_up_the_rules_3: 'TAOGE収益解釈：USDT総収益をTAOGEリアルタイム価格で除し、TAOGEの数に変換する。これがTAOGEの総収益である',
        lock_up_the_rules_4: 'ロック後に取得したUSDT収入は個人口座に移動し、TAOGE収入は2021年8月30日まで凍結され、その後は自由取引を解凍することができる',
        project_introduction: 'プロジェクトの概要',
        project_introduction_content: 'なぜTAOGEメモリブロックファンドはこれほど高い収益を上げることができるのか。我々は安定した資産運用の定量化収益を持っており、その中にはUSDTの国際市場にまたがる高周波ヘッジキャリーがあり、Cloud Cryptal Power機構とモルガン・チェースの前任CFO（Marianne Lake）が直接管理に参加している。コア管理チームは金融分野で15年以上の実戦経験を持ち、ヘッジキャリーのリスクと対応策をよく理解しており、すでにかなり成熟した量子化ヘッジ戦略を形成している',
        lock_period: 'あやまりしゅうき',
        lock_start_time: 'ロック開始時間',
        lock_end_time: 'ロック終了時間',
        state: {
            checking: 'レビュー中',
            locking: 'ロックボックス',
            finished: 'リリースの完了',
            fail: 'に失敗'
        }
    },
    subscribe: {
        title1: '新規通貨購読',
        title2: '私たちはメモリブロックチェーンプロジェクトの資金調達を支援し、支持者を探し、斬新で面白いアイデアを提案することに力を入れています。',
        new_currency: '新貨',
        my_participation: 'マイ購読',
        project_details: 'プロジェクトの詳細',
        subscription_ratio: '購入比率',
        issue_quota: '発行クォータ',
        participating: '参加済み',
        lock_period: 'ラッチサイクル',
        months: 'ヶ月',
        thaw_interval: '解凍間隔',
        hours: '時間',
        available: '使用可能',
        purchase_limit: '参加制限',
        total: '合計＃ゴウケイ＃',
        fee: '手数料',
        get_involved_now: '今すぐ参加',
        end_date: '終了日',
        project_des: 'プロジェクトの概要',
        white_paper: 'ホワイトペーパー'
    },
    login: {
        title: '会員登録',
        dont_have_an_account:'会員アカウントはまだお持ちではありません ?',
        to_register: '登録解除',
        email: 'メールアドレス',
        password: 'ログインパスワード',
        captcha: 'グラフィックス認証コード',
        click_me_show: 'クリックして表示',
        sign_in_now: '今すぐログイン',
        forget_password: 'パスワードを忘れる?',

        emailPhone:'メールアドレスまたは携帯電話番号',
        min6:'（少なくとも6ビット）',
        rules1:'メールアドレスまたは携帯電話番号を入力してください',
        rules2:'最低6桁のパスワードを入力してください',
        rules3:'最低6ビットの繰り返しパスワードを入力してください',
        rules3_2:'2回の入力パスワードが一致しません',
        rules4:'招待コードを入力してください',
        rules5:'登録にはユーザープロトコルを参照して同意する必要があります',
        captcha_plc:'最低4ビット認証コードを入力してください',
    },
    register: {
        title: '会員登録',
        phone: '電話番号',
        re_password: 'パスワードの繰り返し',
        invitation_code: '招待コード',
        email_captcha: 'メールボックス認証コード',
        send_to_email: 'メールボックスに送信',
        already_have_an_account: '会員アカウントを持っています ?',
        sign_in: 'ログイン＃ログイン＃'
    },
    forget: {
        title: 'パスワードのリセット',
        retrieve_now: '今すぐリセット'
    },
    account: {
        title: 'アカウント',
        recharge: 'チャージ',
        profile: '個人情報',
        my_assets: 'マイ資産',
        withdraw: '現金化',
        transactions: '現物取引',
        micro_contract: 'マイクロ契約取引',
        contract:'契約取引',
        inviting_and_awards: '招待とコミッション',
        settings: '設定＃セッテイ＃',
        list_of_assets: '資産リスト',
        available: '使用可能',
        frozen: 'とうけつ',
        available_log: '使用可能なレコード',
        event: 'イベント＃イベント＃',
        time: '時間',
        event_type: {
            recharge: 'チャージ',
            take: '差し引く',
            withdraw: '現金化',
            withdraw_fail: '引き出しに失敗しました',
            trade_buy: '現物買い',
            trade_sell: '現物売り',
            trade_success: '取引成功',
            trade_cancel: '取引取消',
            subscribe: '購読',
            contract_buy: 'マイクロ契約注文',
            contract_back: 'マイクロ契約が戻る',
            contract_win: 'マイクロ契約で利益を得る',
            frozen: 'とうけつ',
            thaw: '解凍',
            fund_buy: 'メモリブロックファンドの購入',
            fund_buy_fail: 'メモリブロック基金の購入に失敗しました',
            fund_back: 'メモリー・ブロック基金元金戻入',
            fund_income: 'メモリブロック基金収益',
            cfl1ua: '永久直進奨励金',
            cfl1u: '直進奨励',
            cfl2u: '2級奨励',
            cfl3u: '3級奨励金',
            exchange: 'フラッシュ引換',

            // 新增
            new25:'契約アカウントへの転送',
            new26:'契約口座振替',
            new27:'法貨チャージ',
            new28:'外貨引き出し',
            new29:'新元予約',
            new30:'奨励金',
            new31:'サービス料',
        },
        email: 'メールアドレス',
        verify: '資格認定',
        verify_state: {
            unsubmitted: 'コミットされていません',
            fail: 'に失敗',
            checking: 'レビュー保留中',
            success: '完了'
        },
        verify_page:{
            rules1:'身分証明書名を入力してください',
            rules2:'身分証明書番号を入力してください',
        },
        addressTitle:'コールドウォレットアドレス',
    },
    wallet: {
		tb: '総資産換算',
		recharge: '貨幣を充填する',
		withdraw: '貨幣をつりあげる',
		exchange: 'フラッシュ引換',
		in_order: 'とうけつ',
		value: '換算',
		pea: '金額を入力してください',
		petp: '資金パスワードを入力してください',
		log_type: [
			'チャージ',
			'差し引く',
			'現金化',
			'引き出しに失敗しました',
			'取引購入',
			'取り引き売り',
			'取引成功',
			'取引取消',
			'貨幣を買う',
			'契約取引による購入',
			'契約取引の引き分け',
			'契約取引の勝局',
			'システム凍結',
			'システム解凍',
			'トークンファンドの購入',
			'トークンファンドの購入に失敗しました',
			'トークンファンドが元金を放出',
			'トークンファンド収益',
			'永久直進奉還',
			'直進奉還',
			'2級奉還',
			'3級返還',
			'フラッシュ引換',
			'フラッシュ引換',
			'フラッシュ引換'
		]
	},
    recharge: {
		title: '貨幣を充填する',
		cc: 'クリックしてコピー',
		uv: '資格情報のアップロード',
		puv: '証明書をアップロードしてください',
		nor: 'チャージを開放しない',
		history: {
			title: 'チャージ記録',
			checking: 'レビュー保留中',
			success: '正常に入金されました',
			fail: 'に失敗'
		},
		tips: {
			USDT: [
				'ネットワークERC 20/TRC 20を選択して、USDTを上記の住所に引き出してください。そうしないと、資産は回収できません。上記アドレスにチャージすると、ネットワークノード全体を確認する必要があり、12回のネットワーク確認後にアカウントを確認し、12回のネットワーク確認後にキャッシュを確認する。',
				'最低チャージ金額200 USDT、最低チャージ金額を下回ると記入されず、返金できません。チャージアドレスは頻繁に変更されません。変更があればチャージできます。サイトのお知らせやメールでお知らせします。',
				'情報の改ざんや流出を防ぐために、コンピュータとブラウザが安全であることを確認してください。'			
			],
			BTC: [
                'ネットワークERC 20/TRC 20を選択して、BTCを上記の住所に引き出してください。そうしないと、資産は回収できません。上記アドレスにチャージすると、ネットワークノード全体を確認する必要があり、12回のネットワーク確認後にアカウントを確認し、12回のネットワーク確認後にキャッシュを確認する。',
				'最低チャージ金額200 USDT、最低チャージ金額を下回ると記入されず、返金できません。チャージアドレスは頻繁に変更されません。変更があればチャージできます。サイトのお知らせやメールでお知らせします。',
				'情報の改ざんや流出を防ぐために、コンピュータとブラウザが安全であることを確認してください。'			
			],
			ETH: [
				'ネットワークERC 20/TRC 20を選択して、ETHを上記の住所に引き出してください。そうしないと、資産は回収できません。上記アドレスにチャージすると、ネットワークノード全体を確認する必要があり、12回のネットワーク確認後にアカウントを確認し、12回のネットワーク確認後にキャッシュを確認する。',
				'最低チャージ金額200 USDT、最低チャージ金額を下回ると記入されず、返金できません。チャージアドレスは頻繁に変更されません。変更があればチャージできます。サイトのお知らせやメールでお知らせします。',
				'情報の改ざんや流出を防ぐために、コンピュータとブラウザが安全であることを確認してください。'			
			],
			LTC: []
		}
	},
    withdraw: {
		title: '貨幣をつりあげる',
		now: '貨幣の引き出しを開放しない',
        swa: 'ウォレットアドレスの選択',
        awa: 'ウォレットアドレスの追加',
        nwa: '新しいウォレットアドレス',
		history: {
			title: '貨幣引出記録',
			checking: 'レビュー保留中',
			success: '成功',
			fail: 'に失敗',
			cancel: 'キャンセル'
		}
	},
    exchange: {
		title: 'フラッシュ引換',
		history: {
			title: 'フラッシュ引換記録'
		}
	},
    share: {
		title: '友達を分かち合う',
		cic: '招待コードのコピー',
		t: 'メモリブロックチェーン基金の普及・共有奨励メカニズム',
		t1: 'ダイレクトプロモーションエージェント',
		t1_1: '1. 直接2人以下を普及させて取引フロー2%USDT奨励金を獲得',
		t1_2: '2. 直接3人以上（3人含む）を普及させて取引フローの4%USDT奨励金を獲得する',
		t1_3: '3. 直接5人以上（5人含む）を普及させ、取引フロー6%USDT奨励金を獲得',
		t1_4: '4. 直接10人以上（10人含む）を普及させて取引フロー8%USDT奨励金を獲得',
		t2: 'セカンダリエージェント',
		t2_1: '1. 2級代理人が4人以下に達し、取引フローの1%USDT奨励金を獲得',
		t2_2: '2. 2級代理は4人（4人を含む）に達し、取引フローの2%USDT奨励金を獲得した',
		t2_3: '3. 2級代理は6人（6人を含む）に達し、取引フローの4%USDT奨励金を獲得した',
		t3: '三次エージェント',
		t3_1: '1. 3級代理店が1人に達し、取引フローの3%USDT奨励金を獲得',
		t3_2: '2. 3級代理は4人（4人を含む）に達し、取引フローの6%USDT奨励金を獲得した',
		t3_3: '3. 3級代理は6人（6人を含む）に達し、取引フローの8%USDT奨励金を獲得した',
		team: {
			title: '返礼を請う',
			ct1: '招待者数の合計',
			ct2: '本日の招待者数',
			ct3: '今日の収益',
			ct4: '合計収益',
			ct5: '合計残高',
			level_1: '1レベル', 
			level_2: '2レベル', 
			level_3: '3レベル',
		}
	},
    user: {
		email: 'メールアドレス',
		email_captcha: 'メールボックス認証コード',
		captcha: 'ピクチャ認証コード',
		pwd: 'パスワード',
		confirm_pwd: 'パスワードの確認',
		trade_pwd: '資金パスワード',
		confirm_trade_pwd: '資金パスワードの確認',
		wa: 'ウォレットアドレス',
		peea: 'メールアドレスを入力してください',
		pewa: 'ウォレットアドレスを入力してください',
		pec: '画像認証コードを入力してください',
		peec: 'メールボックス認証コードを入力してください',
		cfii: '図面認証コードは文字と数字で構成されています',
		ecfii: 'メールボックス認証コードは数値構成',
		eafii: 'メールアドレスフォーマットエラー',
		pep: 'パスワードを入力してください',
		pecp: 'パスワードを確認してください',
		p_diff: '2回入力されたパスワードが一致しません',
		pfii: 'パスワードは英数字と記号で構成されています',
		uff: 'ファイルのアップロードに失敗しました'
	},
    contact_about:{
        label1:'契約書の注文',
        top_text_h1:'ネット',
        top_text_h2:'デポジット',
        top_text_h3:'持倉利益',
        available:'使用可能残高',
        fundswap:'資金交換',
        form:'開始',
        to:'まで',
        pair:'対貨幣',
        num:'数量',
        num_plc:'数量を入力してください',
        all:'すべて',
        cancel:'キャンセル',
        confirm:'を選択して、',
        margin:'デポジット',
        margin1:'残金保証金',
        lever:'レバー',
        please_choose:'選択してください',
        contract_order:'契約オーダ',
        position_order:'在庫品注文',
        current_commission:'現在の委任',
        switchBtn:'現在の品種のみを見る',
        liquidation:'平倉',
        table1:{
            pair:'対貨幣',
            type:'を選択してオプションを設定します。',
            income:'に利益を与える',
            rate_return:'収益率',
            deal:'取り引き',

            span_h1:'委託価格',
            span_h2:'オープン価格',
            span_h3:'デポジット',
            span_h4:'最新価格',
            span_h5:'委任時間',
            span_h6:'開倉時間',
            span_h7:'レバー',

            span_h8:'平倉価格',
            span_h9:'平倉時間',

            service_charge:'手数料',
        },
        table2:{
            type:'を選択してオプションを設定します。',
            price:'価格',
            amount:'数量',
            time:'時間',
            deal:'取り引き',

            service_charge:'サービス料',
        },
        recharge_text1:"USDT現品財布",
        recharge_text2:"USDT契約財布",
        error1:'残高より大きくない数量を入力してください',
    },
    recharge_new:{
        verified_tip:'チャージ機能は認証を受けてからご利用ください',
        table_item1:'注文番号',
        table_item2:'ステータス',
        table_item3:'を選択してオプションを設定します。',
        table_item4:'金額',
        table_item5:'資格情報',
        table_item6:'時間',

        table_item7:'アドレス',

        table_state1:'コミット中',
        table_state2:'成功',
        table_state3:'に失敗',

        amount_mes:'純数値で最大2桁の小数点以下を保持',
        amount_mes2:'純数値で最大8桁の小数点以下を保持',
        message_img:'証明書の画像をアップロードしてください',
        message_password:'トランザクションパスワードを空にすることはできません',
        message_bal:'引き出し金額は残高より大きくすることはできません',

        table_item_new1:'転入通貨',
        table_item_new2:'転入数量',
        table_item_new3:'入金通貨',
        table_item_new4:'入金数量',
    },
    address:{
        title:'コールドウォレットアドレス',
        wallet_address:'コールドウォレットの住所を入力してください',
        wallet_list_title:'コールドウォレットリスト',
        wallet_type:'を選択してオプションを設定します。',
        wallet_address_title:'コールドウォレットアドレス',
        wallet_opea:'操作',
        wallet_cancel:'キャンセル',
        wallet_err:'ウォレットアドレスを空にすることはできません',
        wallet_tip:'10個のコールドウォレットを制限',
    },
    //現貨訂單列表
    transactions:{
        span1:'を選択してオプションを設定します。',
        span2:'通貨/機能',
        span3:'委託価格',
        span4:'数量',
        span5:'成約価格',
        span6:'合計価格',
        span7:'時間',
        span8:'ステータス',

        cancelBtn:'注文の取消',

        state_new1:'発注依頼',
        state_new2:'ユーザーの元に戻す',
        state_new3:'成約オーダー',
        state_new4:'システムの元に戻す',
    },
    micro_contract:{
        span1:'を選択してオプションを設定します。',
        span2:'通貨/機能',
        span3:'数量',
        span4:'サイクル/収益率',
        span5:'に利益を与える',
        span6:'決済価格',
        span7:'決済時間',
        span8:'ステータス',

        state1:'処理待ち',
        state2:'成功',
        state4:'に失敗',
    },

    // 現貨詳情
    trading_index:{
        el_title:'現品注文',
        el_title1:'発注',
        el_switch1:'現在の品種のみを見る',
        message1:'購入総額は残高を超えてはならない',
        message2:'購入数量は残高を超えてはならない',

        // 新加市價限價
        tab_1:'市価委託',
        tab_2:'価格制限依頼',
    },

    user_contract:{
        placeholder1:'選択してください',
        span1:'元に戻す',
        span2:'ユーザーの元に戻す',
        span4:'平倉になった',
        span5:'システムの元に戻す',
        rentext1:'すべての通貨',
        rentext2:'すべてのタイプ',
        rentext3:'購入注文',
        rentext4:'販売オーダ',
        rentext5:'すべての状態',
        rentext6:'発注依頼',
        rentext7:'ユーザーの元に戻す',
        rentext8:'成約する',
        rentext9:'平倉',
        rentext10:'システムの元に戻す',
    },

    //個人設置導航
    settings:{
        nav1:'KYC認証',
        nav2:'ログインパスワード',
        nav3:'トランザクションパスワード',
        nav4:'ログオンレコード',
        ipAddress:"IPアドレス",
        Date_time:"時間",
    },

    fiat_currency:{
        message:{
            tips:'ヒント',
            p_userToken:'先にログインしてください',
            p_userVerified:'まず実名認証を受けてください',
            confirm:"確認",
            cancel:"キャンセル",
        },
        onTab1:"外貨両替",
        onTab2:"USDTエクスプレス・セールス",
        onTab3:"ドル入金",
        onTab4:"法定貨幣支払",
        span1:"トレーダー",
        span2:"数量",
        span3:"割当て制限",
        span4:"単価",
        span5:"支払い方法",
        span6:"取り引き",
        span7:"手数料",
        bank:"銀行カード",
        open:"USDTの購入",

        fait_pay:{
            inp1:'チャージ金額（USDT）',
            plc1:'チャージ金額を入力してください',
            inp2:'支払金額',
            plc2:'支払金額の自動計算',
        },

        recycle:{
            inp1:"販売総数量",
            plc1:"販売総数量",
            inp2:"通貨＃ツウカ＃",
            plc2:"通貨を選択してください",
            inp3:"売却総額  ",
            plc3:"売却総額",
            inp4:"銀行カード",
            plc4:"銀行カードを選択してください",
            submitForm:"送信",
            recycling_tips_title:"特別な注意事項",
            recycling_tip1:"プラットフォーム認証実名銀行カードを使用してください。受取人の名前とプラットフォーム登録の名前が一致しないと回収できません。",
            recycling_tip2:"注文のピーク時には、大量の注文を処理するため、返金時間が長くなる可能性がありますので、お待ちください。",
            recycling_tip3:"USDTエクスプレス販売集中回収時間は12.00 AMおよび05：00 PM（法定休日または非稼働日を除く）。",
        },
        public:{
            dt:"公的勘定情報",
            dd1:"受取人",
            dd2:"入金口座",
            dd3:"銀行名",
            dd4:"銀行住所",
            bankFn:"ドル入金",
        },
        dialog1:{
            title:"購入する",
            h5:"支払前に必読",
            p1:"重要：振替の注意事項振替時に注意しないでください。",
            p2:"振替の注意事項：振替時に「仮想通貨」「USDT」などの仮想通貨に関する用語を注記しないでください。銀行カードが凍結と振替にブロックされないようにしてください。",
            p3:"口座の実名認証された名前の下の銀行カードを使用して振替を行います。",
            p4:"ピーク時はお金を入れるのが少し遅いので、辛抱強く待ってください。",
            span1:"数量",
            span2:"割当て制限",
            span3:"単価",
            buynum:"購入数量を入力してください",
            allbuyFn:"すべて購入",
            pay_for:"実支払",
            comeFn:"確認",
        },
        dialog2:{
            title:"ドル入金",
            label1:"金額",
            placeholder1:"金額を入力してください",
            label2:"資格情報",
            submitBank:"送信",
        },
        rules:{
            message1:"販売総数量を入力してください",
            message2:"銀行カードを選択してください",
            message3:"金額を入力してください",
            message4:"証明書をアップロードしてください",
            err_message1:"購入数量を入力してください",
            err_message2:"払込金額は限度区間内でなければならない",
            err_message3:"購入数量が保有数量を超えている",
        },

        buy:{
            h2:"USDTの購入",
            span1:"数量",
            span2:"單價",
            span3:"実支払",
            item2_h:"売手入金方法",
            item2_p1:"必ずご本人をご利用ください",
            item2_p2:"の支払い方法により以下のアカウントに自動振替",
            msg_h1:"入金銀行",
            msg_h2:"支店名",
            msg_h3:"銀行口座",
            msg_h4:"銀行コード",
            msg_h5:"送金メモ",
            msg_h6:"資格証明のアップロード",
            upload_text:"ファイルをここにドラッグするか、[アップロード]をクリックします",
            upload_tip:"8 m未満の画像ファイルのみをアップロードできます",
            submitFn:"資格証明の発行",
            dealers_img:"トレーダー",
            flag_no:"すでに全データです",
            placeholder:"コンテンツを入力し、[Enter]キーを押してコミットしてください",
            message1:"文字を入力するか、画像送信を選択してください",
            message2:"送信に失敗しました",
        },
    },

    index:{
		guide_box:{
			item1:"登録",
			item2:"入金",
			item3:"取り引き",
			item4:"取り引き",
			item1_text:"登録はすぐに10 USDTを取得",
			item2_text:"入金は直ちに20 USDTを取得",
			item3_text:"トランザクションはすぐに50 USDTを取得",
			item4_text:"取引が10000 USDTを超えるとすぐに100 USDTを取得",
		},
    },

    Help_center:"ヘルプセンター",
    account_fiat1:"銀行カード",
    account_fiat2:"外貨建て注文書",
    account_fiat3:"USDTエクスプレス受注",

    account_fiat4:" 新規通貨発注",

    account_bank:{
        placeholder0:"通貨を選択してください",

        placeholder1:"受取人を入力してください",
        placeholder2:"入金銀行を入力してください",
        placeholder3:"支店情報を入力してください",
        placeholder4:"入金口座を入力してください",
        placeholder5:"銀行コードを入力してください",
        submitForm:"送信",
        list_title:"銀行カードリスト",
        list_span1:"受取人",
        list_span2:"銀行名",
        list_span3:"支店情報",
        list_span4:"收款賬戶",
        list_span5:"振替コード",
        list_span6:"操作",
        onDel:"削除＃サクジョ＃",
        message1:"受取人を入力してください",
        message2:"請輸入收款銀行",
        message3:"入金口座を入力してください",
        err_message1:"銀行カードは10枚までとなっております",
        onDel_h:"この操作は銀行カードを永久に削除します。続行しますか？",
        onDel_tips:"ヒント",
        confirmButtonText:"を選択して、",
        cancelButtonText:"キャンセル",
    },
    account_fiat:{
        title:"外貨建て注文書",
        select_placeholder:"状態を選択してください",
        select_val1:"進行中",
        select_val2:"完了",
        select_val3:"オーダー失敗",
        col1:"注文番号",
        col2:"金額",
        col3:"通貨＃ツウカ＃",
        col4:"価格",
        col5:"法貨金額",
        col6:"ステータス",
        status1:"進行中",
        status2:"完了",
        status3:"オーダー失敗",
        span_h1:"受取人名",
        span_h2:"入金銀行",
        span_h3:"支店名",
        span_h4:"入金口座",
        span_h5:"銀行コード",
        span_h6:"銀行住所",
        span_h7:"送金メモ",
        span_h8:"作成時間",
        span_h9:"資格情報",
    },
    account_recycle:{
        title:"USDTエクスプレス受注",
        select:"状態を選択してください",
        select_val1:"進行中",
        select_val2:"完了",
        select_val3:"オーダー失敗",
        span1:"注文番号",
        span2:"金額",
        span3:"通貨＃ツウカ＃",
        span4:"価格",
        span5:"法貨金額",
        span6:"ステータス",
        status1:"進行中",
        status2:"完了",
        status3:"オーダー失敗",
        span_h1:"受取人名",
        span_h2:"入金銀行",
        span_h3:"支店名",
        span_h4:"入金口座",
        span_h5:"銀行コード",
        span_h6:"銀行住所",
        span_h7:"送金メモ",
        span_h8:"作成時間",
        span_h9:"資格情報",
    },
    settings_password:{
        placeholder1:"古いパスワードを入力してください",
        placeholder2:"新しいパスワードを入力してください",
        placeholder3:"新しいパスワードを再入力してください",
        submit:"送信",
        message:"2回入力したパスワードは同じでなければなりません",
    },
    settings_paypassword:{
        placeholder1:"メールアドレスを入力してください",
        placeholder2:"新しい取引パスワードを入力してください",
        placeholder3:"新しい取引パスワードを再入力してください",
        placeholder4:"メールボックス認証コードを入力してください",
        submit:"送信",
        send:"メールを送信",
        message:"2回入力したパスワードは同じでなければなりません",
    },
    account_details:{
        router:"契約財布の詳細",
        span1:"を選択してオプションを設定します。",
        span2:"ステータス",
        span3:"金額",
        span4:"時間",
        type0:"買い増し",
        type1:"買いが落ちる",
        type2:"転入",
        type3:"転出",
        type4:"決済",
        type5:"手数料",
        type6:"システムレベリング",
        type7:"システムオペレーション",
        state0:"処理待ち",
        state1:"完了",
        state2:"に失敗",
    },

    r:{
        r1:"登録には閲覧と同意が必要です",
        r2:"ユーザプロトコル",
    },

    new_list:{
        h:"新貨相場",
        span2:"発行価格",
        span3:"発行日",
    },
    issue:{
        onOrderShow:"購買依頼",
        issue_amount:"発行部数",
        use_issue_amount:"購入済み数量",
        close_day:"クローズ日数",
        label:"購買依頼数量",
        placeholder:"購買依頼数量を入力してください",
        Cancel:"キャンセル",
        Submit:"送信",
        detail:"詳細",
    },
    issue_order:{
        col1:"取引ペア",
        col2:"買入れ価格",
        col3:"数量",
        col4:"合計金額",
        col5:"クローズ日数",
        col6:"作成時間",
    },

    contract_shenqin:{
        text_notice1:'実名認証を提出していません、まず実名認証を提出してください',
        text_notice2:'実名認証に失敗しました。実名認証を再提出してください',
        text_notice3:'実名認証はレビュー中です',
        tips:'ヒント',
        confirmButtonText:'確認',
        cancelButtonText:'キャンセル',
        // confirm_text:'合約交易需要資產達到10000USDT以上，請提交申請',
        confirm_text:'契約申請を提出して、確認をクリックして申請を完了してください。申請が成功すれば取引ができます',
        dataVerify_text:'契約取引権限申請は処理中ですので、処理をお待ちください',
    },


    is_cancel_order:"本当にこの注文をキャンセルしますか？",
    cancel_order:"注文の取消",
    send_img:"資格証明のアップロード",

    // 現貨新增市價限價
    youjia:"現在の最適価格で取引",
    shijia_chao:"使用可能残高超過",
    allBuyValueFnShijia_p:"金額",
    allSellValueFnShijia_p:"数量",

    xinbiyure:{
        issue_price:'発行価格',
        issue_date:'発行日',
        issue_amount:'発行部数',
        guimo:'募金規模',
        use_issue_amount:'累計購買依頼額',
        close_day:'凍結日数',
        published_date:'当選発表日',
        showToast1:"申請可能限度を超える",
        showToast1_rengou:"余剰予約額超過",
        issue_plc:"購買依頼金額を入力してください",

        can_num_h:"申請可能限度",
        orderFormData_amount1:"購買依頼金額を入力してください",
        can_num_h1:"余剰予約額",
        orderFormData_amount2:"購入金額を入力してください",

        // 新增可用餘額usdt（現貨）
        can_num_h_u:"使用可能残高",  
        showToast_u:"使用可能残高超過",

        span1:"取引ペア",
        span2:"購買依頼金額",
        span3:"ステータス",
        span4:"くじに当たる額",
        span5:"余剰予約額",
        span6:"購買依頼日時",
        span7:"操作",
        buyFn:"購読する",
        state_arr1:"購買依頼中",
        state_arr2:"くじに当たる",
        state_arr3:"完了",
        state_arr4:"完了",
        amount_message:"購入金額を入力してください",
    },

    recharge_manage:{
        title:'管理費財布'
    },

    money_error:{
        text1:'空にすることはできません',
        text2:'2桁の小数点しか保持できません',
    },
}